/**
 * Created by 510004 on 1/24/2020.
 */

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { withAuthorization } from '../components/session';
import MapContainer from '../components/maps';
import CustomBreadcrumbs from '../components/breadcrumbs';
import { store } from '../_helpers';
import * as ROUTES from '../_constants/routeConstants';
import { navActions } from '../_actions';
import _ from 'lodash';

const TITLE = 'Map View';

const styles = (theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        height: '100%',
        width: '100%',
    },
    fieldsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        padding: '10px',
        gridGap: '10px',
    },
    fieldContainer: {
        display: 'grid',
    },
    formContainer: {
        display: 'grid',
        height: '100%',
        gridTemplateRows: 'auto auto 1fr',
        padding: '20px',
    },
    header: {
        alignItems: 'baseline',
        display: 'grid',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: '1.25rem',
        fontWeight: '700',
    },
    runsHeaderText: {
        fontSize: 'inherit',
        fontWeight: '600',
    },
});

function MapView(props) {
    let [lat, setLat] = useState(null);
    let [long, setLong] = useState(null);
    const { classes, dispatch } = props;

    const [searchParams] = useSearchParams();
    const searchVals = searchParams.get('coords');

    useEffect(() => {
        const split = searchVals.split(',');
        if (split.length === 2) {
            lat = parseFloat(split[0].trim());
            long = parseFloat(split[1].trim());

            setLat(lat);
            setLong(long);

            if (!_.isNil(lat) && !_.isNil(long)) {
                const { navOperation } = store.getState();
                const { from, to } = navOperation;

                const target = { title: `${lat},${long}`, url: `../${ROUTES.MAPS}${lat},${long}` };
                const orderedLinks = [from, to];

                dispatch(navActions.setBreadcrumbs(orderedLinks));
                dispatch(navActions.setTo(target));
            }
        }
    }, [searchVals]);

    return (
        <div className={classes.formContainer}>
            <CustomBreadcrumbs />
            <div className={classes.header}>
                <span className={classes.headerText}>{TITLE}</span>
            </div>
            <MapContainer google={window.google} lat={lat} long={long} />
        </div>
    );
}

MapView.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    lat: PropTypes.number,
    long: PropTypes.number,
    onRefreshClick: PropTypes.func,
};

const condition = (authUser) => !!authUser;

export default connect()(withStyles(styles)(withAuthorization(condition)(MapView)));
