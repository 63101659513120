/**
 * Created by 510004 on 2/19/2020.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { CustomPagination, EnhancedTableHead, TableCellLink } from '../table/index';
import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { compose } from 'recompose';
import { listUtilsService } from '../../_services';
import { createTheme } from '@material-ui/core/styles';

const styles = (theme) => ({
    container: {
        maxHeight: 'calc(100vh - 400px)',
    },
    root: {
        width: '100%',
        display: `grid`,
        gridTemplateRows: `1fr auto`,
    },
    table: {
        minWidth: 700,
    },
    tableBody: {
        display: 'block',
        overflowX: 'visible',
        overflowY: 'scroll',
        height: '60vh',
    },
    row: {
        height: 'auto',
    },
    tableHeadTooltip: {
        color: theme.palette.common.white,
    },
    sortLabelRoot: {
        color: theme.palette.common.white,
        '&:focus': {
            color: theme.palette.common.white,
        },
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    sortLabelActive: {
        color: theme.palette.common.white,
    },
    buttonContainer: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        position: 'relative',
    },
});

const theme = createTheme({
    overrides: {
        MuiIconButton: {
            root: {
                padding: '6px 12px',
            },
        },
        MuiTablePagination: {
            toolbar: {
                minHeight: 'auto',
            },
        },
        MuiTableCell: {
            root: {
                fontSize: 'inherit',
            },
        },
    },
});

const columns = [
    {
        id: 'caption',
        label: 'Caption',
        align: 'left',
    },
    {
        id: 'firmwareRevision',
        label: 'Firmware Revision',
        align: 'center',
    },
    {
        id: 'altusId',
        label: 'Altus Id',
        align: 'center',
    },
    {
        id: 'toolId',
        label: 'Tool Id',
        align: 'center',
    },
    {
        id: 'hardwareId',
        label: 'Hardware Id',
        align: 'center',
    },
    {
        id: 'hardwareRevision',
        label: 'Hardware Revision',
        align: 'center',
    },
    {
        id: 'leinRevision',
        label: 'LEIN Revision',
        align: 'center',
    },
];

function ToolInformationList(props) {
    const { classes, toolInformationList } = props;

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('lastAccessedDate');
    const [orderType, setOrderType] = useState('date');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(-1);

    // Variables.
    let currentRowsPerPage = rowsPerPage;
    let currentOrder = order;
    let currentOrderBy = orderBy;
    let currentOrderType = orderType;
    let currentPage = page;

    const handleRequestSort = (event, property) => {
        setOrderBy(property);
        currentOrderBy = property;

        const col = columns.find((column) => column.id === orderBy);
        if (col && col.type) {
            setOrderType(col.type);
            currentOrderType = col.type;
        }

        setOrder('desc');
        currentOrder = 'desc';

        if (orderBy === property && order === 'desc') {
            setOrder('asc');
            currentOrder = 'asc';
        }
    };

    const handleChangePage = (event, page) => {
        setPage(page);
        currentPage = page;
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        const rows = parseInt(event.target.value, 10);
        setRowsPerPage(rows);
        currentRowsPerPage = rows;
    };

    const pageSizeOptions = listUtilsService.getPageSizeOptions(toolInformationList.length);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, toolInformationList.length - page * rowsPerPage);

    return (
        <ThemeProvider theme={theme}>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table className={classes.table} stickyHeader aria-label="a dense sticky table" size="small">
                        <EnhancedTableHead
                            columns={columns}
                            onRequestSort={handleRequestSort}
                            order={currentOrder}
                            orderBy={currentOrderBy}
                            rowCount={toolInformationList.length}
                        />
                        <TableBody>
                            {(rowsPerPage > 0
                                ? listUtilsService
                                      .stableSort({
                                          array: toolInformationList,
                                          cmp: listUtilsService.getSorting({
                                              order: currentOrder,
                                              orderBy: currentOrderBy,
                                              orderType: currentOrderType,
                                          }),
                                      })
                                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : listUtilsService.stableSort({
                                      array: toolInformationList,
                                      cmp: listUtilsService.getSorting({
                                          order: currentOrder,
                                          orderBy: currentOrderBy,
                                          orderType: currentOrderType,
                                      }),
                                  })
                            ).map((row, index) => {
                                const { id } = row;
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={`${id}-${index}`}>
                                        {columns.map((column) => {
                                            const value = row[column.id] || column['default'];

                                            return column.type &&
                                                column.type === 'link' &&
                                                value !== undefined &&
                                                value !== null ? (
                                                <TableCellLink
                                                    key={`linkcell-${column.id}-${id}`}
                                                    column={column}
                                                    row={row}
                                                    value={value}
                                                />
                                            ) : (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ width: column.minWidth }}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <CustomPagination
                    colSpan={columns.length}
                    count={toolInformationList.length}
                    rowsPerPage={currentRowsPerPage}
                    page={currentPage}
                    pageSizeOptions={pageSizeOptions}
                    onRequestChangePage={handleChangePage}
                    onRequestChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </ThemeProvider>
    );
}

ToolInformationList.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    toolInformationList: PropTypes.array,
};

const ToolInformation = compose(withStyles(styles))(ToolInformationList);

export default ToolInformation;
