/**
 * Created by 510004 on 2/20/2020.
 */

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const styles = (theme) => ({
    head: {
        backgroundColor: '#5790b8',
        color: theme.palette.common.white,
    },
});

function CustomTableHeaderCellBase(props) {
    const createSortHandler = (property) => (event) => {
        props.onRequestSort(event, property);
    };

    const { order, orderBy, classes } = props;
    let { column } = props;
    column = column || {};

    return (
        <TableCell
            align={column.align}
            key={column.id}
            style={{ width: column.minWidth }}
            sortDirection={orderBy === column.id ? order : false}>
            {!column.disableSort && (
                <TableSortLabel
                    classes={{
                        active: classes.sortLabelActive,
                        root: classes.sortLabelRoot,
                    }}
                    active={orderBy === column.id}
                    direction={order}
                    onClick={createSortHandler(column.id)}>
                    {column.label}
                </TableSortLabel>
            )}
            {column.disableSort && column.label}
        </TableCell>
    );
}

CustomTableHeaderCellBase.propTypes = {
    classes: PropTypes.object.isRequired,
    column: PropTypes.object,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

export const CustomTableHeaderCell = withStyles(styles)(CustomTableHeaderCellBase);
