/**
 * Created by 700290 on 4/27/2024.
 */
'use strict';

import React, { useState, useEffect } from 'react';
import { Button, Box, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { InputLabel, MenuItem, Select, FormControl, Grid, CardHeader, CardContent } from '@mui/material';
import { GridLoader } from 'react-spinners';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    card: {
        minWidth: '800px',
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    roleList: {
        marginTop: theme.spacing(2),
    },
    loaderContainer: {
        alignSelf: 'center',
        display: 'grid',
        justifySelf: 'center',
    },
    loaderParentContainer: {
        display: 'grid',
        height: '100%',
    },
}));
const LOADER_SIZE_PX = 20;
const LOADER_COLOR = '#105F9A';
const ManageExternalUsersComponent = () => {
    const classes = useStyles();
    const [roles, setRoles] = useState([]);
    const [userName, setUserName] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [loading, setLoading] = useState(true);
    const [fetchUsers, setFetchUsers] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };
    const handleAdd = async () => {
        try {
            setLoading(true);
            const authUser = JSON.parse(localStorage.getItem('authUser'));
            const authHeader = {
                Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken,
                'Content-Type': 'application/json',
            };

            const response = await fetch('/api/addClientUserWithRoleClaim', {
                method: 'POST',
                headers: authHeader,
                body: JSON.stringify({
                    userName: userName,
                    password: password,
                    fullName: fullName,
                    roleName: selectedRole,
                }),
            });
            const data = await response.text();
            if (!response.ok) {
                throw new Error(data || 'Something went wrong');
            }
            setFetchUsers((prevState) => !prevState);
            handleResponse(data, 'success', true);
        } catch (error) {
            handleResponse(error.message, 'error', false);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleResponse = (status, severity, clearState) => {
        setMessage(status);
        setSeverity(severity);
        setLoading(false);
        setOpen(true);
        clearControlsState(clearState);
    };
    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };
    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleFullNameChange = (event) => {
        setFullName(event.target.value);
    };
    const clearControlsState = (shallClear) => {
        if (shallClear) {
            //setSelectedUsers([]);
            setSelectedRole('');
        }
    };
    useEffect(() => {
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        const requestOptions = {
            method: 'GET',
            headers: { Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken },
        };

        fetch('/api/getUsers', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setRoles(data.roles);
                setLoading(false);
            })
            .catch((error) => handleResponse(error.message, 'error', false));
    }, [fetchUsers]);

    return (
        <div>
            {loading ? (
                <div className={classes.loaderParentContainer}>
                    <div className={classes.loaderContainer}>
                        <GridLoader size={LOADER_SIZE_PX} color={LOADER_COLOR} loading={loading} />
                    </div>
                </div>
            ) : (
                <div>
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        sx={{ top: '30%' }}>
                        <Alert onClose={handleClose} severity={severity}>
                            {message}
                        </Alert>
                    </Snackbar>
                    <Box className={classes.root} sx={{ width: '50%', margin: '0 auto' }}>
                        <Card className={classes.card}>
                            <CardHeader title="Add External Users" />
                            <CardContent>
                                <form autoComplete="off">
                                    <Box className={classes.form}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    label="Username"
                                                    fullWidth
                                                    value={userName}
                                                    autoComplete="username"
                                                    onChange={handleUserNameChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    label="Password"
                                                    fullWidth
                                                    type="password"
                                                    value={password}
                                                    autoComplete="new-password"
                                                    onChange={handlePasswordChange}
                                                />
                                            </Grid>

                                            {/* Second row: Full Name and Select control */}
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    label="Full Name"
                                                    fullWidth
                                                    value={fullName}
                                                    onChange={handleFullNameChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FormControl variant="standard" sx={{ width: '100%' }}>
                                                    <InputLabel id="role-select-label">Roles</InputLabel>
                                                    <Select
                                                        labelId="role-select-label"
                                                        id="role-select"
                                                        value={selectedRole}
                                                        onChange={handleRoleChange}>
                                                        {roles.map((role, index) => (
                                                            <MenuItem key={index} value={role}>
                                                                {`${role}`}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {/* Third row: Button */}
                                            <Grid item xs={12} align="center">
                                                <Button variant="contained" color="primary" onClick={handleAdd}>
                                                    Add
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </form>
                            </CardContent>
                        </Card>
                    </Box>
                </div>
            )}{' '}
        </div>
    );
};

export default ManageExternalUsersComponent;
