/**
 * Created by 510004 on 2/3/2020.
 */

import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import MaterialIcon from '../materialIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import _ from 'lodash';

const styles = () => ({});

function TableCellLinkBase(props) {
    const { classes, column, row, value } = props;
    let finalPath;

    if (!_.isNil(column.route)) {
        finalPath = `${column.route}`;
    }

    if (!_.isNil(column.paramField)) {
        finalPath = `${column.route}${row[column.paramField]}`;
    }
    if (!_.isNil(column.paramFields)) {
        let keys = Object.keys(column.paramFields);
        const parsedParams = [];
        for (let i = 0, len = keys.length; i < len; i++) {
            const key = keys[i];
            parsedParams.push(`${row[column.paramFields[key]]}`);
        }

        finalPath = `${column.route}${parsedParams.join('/')}`;
    }

    if (!_.isNil(column.queryParams)) {
        let keys = Object.keys(column.queryParams);
        const parsedParams = [];
        for (let i = 0, len = keys.length; i < len; i++) {
            const key = keys[i];
            parsedParams.push(`${key}=${row[column.queryParams[key]]}`);
        }

        finalPath = `${column.route}${parsedParams.join('&')}`;
    }

    return (
        <TableCell align={column.align} style={{ width: column.minWidth }}>
            <Link key={`record-${row['id']}`} to={finalPath} className={classes.link}>
                {column.icon
                    ? MaterialIcon({ iconName: column.icon })
                    : column.format && typeof value === 'number'
                      ? column.format(value)
                      : value}
            </Link>
        </TableCell>
    );
}

TableCellLinkBase.propTypes = {
    classes: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    value: PropTypes.any,
};

export const TableCellLink = compose(withStyles(styles))(TableCellLinkBase);
