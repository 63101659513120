/**
 * Created by 510004 on 8/29/2019.
 */
import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = () => ({
    container: {
        display: 'grid',
        color: '#757575',
        fontSize: '0.7rem',
        gridGap: '10px',
        gridTemplateColumns: '1fr auto 24px',
        gridTemplateRows: 'auto',
        gridTemplateAreas: `
            "company extras ."
            `,
        padding: '5px',
    },
    company: {
        gridArea: 'company',
        justifySelf: 'flex-end',
    },
    extras: {
        gridArea: 'extras',
        justifySelf: 'flex-end',
    },
    link: {
        color: 'inherit',
        textDecoration: 'none',
    },
});

const year = new Date().getFullYear();

function FooterBase(props) {
    const { classes } = props;

    return (
        <div className={classes.container}>
            <div className={classes.company}>
                <a
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.altusintervention.com">
                    ALTUS Intervention
                </a>
                <span> &copy; {year}</span>
            </div>
            <div className={classes.extras}>| Built by Technique in Houston, TX</div>
        </div>
    );
}

FooterBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Footer = compose(withStyles(styles))(FooterBase);

export default Footer;
