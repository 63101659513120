/**
 * Created by 510004 on 2/21/2019.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../firebase';
import * as ROUTES from '../../_constants/routeConstants';

const withAuthorization = (condition) => (Component) => {
    function WithAuthorization(props) {
        const navigate = useNavigate();
        let listener;

        useEffect(() => {
            listener = props.firebase.auth.onAuthStateChanged((authUser) => {
                if (!condition(authUser)) {
                    navigate(`../${ROUTES.SIGN_IN}`);
                }
            });

            return () => {
                listener();
            };
        }, [props]);

        return (
            <AuthUserContext.Consumer>
                {(authUser) => (condition(authUser) ? <Component {...props} /> : null)}
            </AuthUserContext.Consumer>
        );
    }

    WithAuthorization.propTypes = {
        firebase: PropTypes.object,
        history: PropTypes.object,
    };

    return compose(withFirebase)(WithAuthorization);
};

export default withAuthorization;
