/**
 * Created by 700290 on 4/27/2024.
 */
'use strict';

import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Checkbox,
    ListSubheader,
    CardHeader,
    CardContent,
    Grid,
} from '@mui/material';
import { GridLoader } from 'react-spinners';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    card: {
        minWidth: '800px',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    roleList: {
        marginTop: theme.spacing(2),
    },
    loaderContainer: {
        alignSelf: 'center',
        display: 'grid',
        justifySelf: 'center',
    },
    loaderParentContainer: {
        display: 'grid',
        height: '100%',
    },
}));
const LOADER_SIZE_PX = 20;
const LOADER_COLOR = '#105F9A';
const ManageUserRolesComponent = () => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [loading, setLoading] = useState(true);
    const [fetchUsers, setFetchUsers] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };
    const handleAdd = async () => {
        try {
            setLoading(true);
            const authUser = JSON.parse(localStorage.getItem('authUser'));
            const authHeader = {
                Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken,
                'Content-Type': 'application/json',
            };
            const selectedUids = users.filter((user) => selectedUsers.includes(user.email)).map((user) => user.uid);

            const response = await fetch('/api/addUserRoleClaim', {
                method: 'POST',
                headers: authHeader,
                body: JSON.stringify({ userIds: selectedUids, roleName: selectedRole }),
            });
            const data = await response.text();
            if (!response.ok) {
                throw new Error(data || 'Something went wrong');
            }
            setFetchUsers((prevState) => !prevState);
            handleResponse(data, 'success', true);
        } catch (error) {
            handleResponse(error.message, 'error', false);
        }
    };

    const handleRemove = async () => {
        try {
            setLoading(true);
            const authUser = JSON.parse(localStorage.getItem('authUser'));
            const authHeader = {
                Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken,
                'Content-Type': 'application/json',
            };
            const selectedUids = users.filter((user) => selectedUsers.includes(user.email)).map((user) => user.uid);
            const response = await fetch('/api/removeUserRoleClaim', {
                method: 'POST',
                headers: authHeader,
                body: JSON.stringify({ userIds: selectedUids, roleName: selectedRole }),
            });

            const data = await response.text();
            if (!response.ok) {
                throw new Error(data || 'Something went wrong');
            }
            setFetchUsers((prevState) => !prevState);
            handleResponse(data, 'success', true);
        } catch (error) {
            handleResponse(error.message, 'error', false);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleResponse = (status, severity, clearState) => {
        setMessage(status);
        setSeverity(severity);
        setLoading(false);
        setOpen(true);
        clearControlsState(clearState);
    };

    const clearControlsState = (shallClear) => {
        if (shallClear) {
            setSelectedUsers([]);
            setSelectedRole('');
        }
    };
    const handleCheckboxChange = (event, email) => {
        if (event.target.checked) {
            setSelectedUsers([...selectedUsers, email]);
        } else {
            setSelectedUsers(selectedUsers.filter((userEmail) => userEmail !== email));
        }
    };

    const handleUserChange = (event) => {
        setSelectedUsers(event.target.value);
    };
    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    useEffect(() => {
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        const requestOptions = {
            method: 'GET',
            headers: { Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken },
        };

        fetch('/api/getUsers', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setUsers(data.users);
                setRoles(data.roles);
                setLoading(false);
            })
            .catch((error) => handleResponse(error.message, 'error', false));
    }, [fetchUsers]);

    return (
        <div>
            {loading ? (
                <div className={classes.loaderParentContainer}>
                    <div className={classes.loaderContainer}>
                        <GridLoader size={LOADER_SIZE_PX} color={LOADER_COLOR} loading={loading} />
                    </div>
                </div>
            ) : (
                <div>
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        sx={{ top: '30%' }}>
                        <Alert onClose={handleClose} severity={severity}>
                            {message}
                        </Alert>
                    </Snackbar>
                    <Box className={classes.root} sx={{ width: '50%', margin: '0 auto' }} noValidate autoComplete="off">
                        <Card className={classes.card}>
                            <CardHeader title="Manage User Roles" />
                            <CardContent sx={{ width: '50%', margin: '0 auto' }}>
                                <Box className={classes.form}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl variant="standard" sx={{ width: '100%' }}>
                                                {/*<TextField id="uid" label="Uid" value={uid} onChange={(e) => setUid(e.target.value)} />*/}
                                                <InputLabel id="user-select-label">Users</InputLabel>
                                                <Select
                                                    labelId="user-select-label"
                                                    id="user-select"
                                                    value={selectedUsers}
                                                    multiple
                                                    onChange={handleUserChange}
                                                    renderValue={(selected) => selected[selected.length - 1]}>
                                                    {users.map((user, index) => (
                                                        <div key={index}>
                                                            <ListSubheader key={index}>
                                                                <Checkbox
                                                                    checked={selectedUsers.indexOf(user.email) > -1}
                                                                    onChange={(event) =>
                                                                        handleCheckboxChange(event, user.email)
                                                                    }
                                                                />
                                                                {user.email}
                                                            </ListSubheader>
                                                            {user.roles.map((role, roleIndex) => (
                                                                <MenuItem key={roleIndex} value={role}>
                                                                    <Box pl={8}>
                                                                        <Typography variant="body2">{role}</Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl variant="standard" sx={{ width: '100%' }}>
                                                {/*<TextField id="uid" label="Uid" value={uid} onChange={(e) => setUid(e.target.value)} />*/}
                                                <InputLabel id="role-select-label">Roles</InputLabel>
                                                <Select
                                                    labelId="role-select-label"
                                                    id="role-select"
                                                    value={selectedRole}
                                                    onChange={handleRoleChange}>
                                                    {roles.map((role, index) => (
                                                        <MenuItem key={index} value={role}>
                                                            {`${role}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} container justifyContent="center" spacing={2}>
                                            <Grid item xs={6} sm={3}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleAdd}
                                                    fullWidth>
                                                    Add
                                                </Button>
                                            </Grid>

                                            <Grid item xs={6} sm={3}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleRemove}
                                                    fullWidth>
                                                    Remove
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>

                        <Typography variant="h6" className={classes.roleList}>
                            Selected Users
                        </Typography>
                        <List>
                            {selectedUsers.map((user, index) => (
                                <ListItem key={index}>{user}</ListItem>
                            ))}
                        </List>
                    </Box>
                </div>
            )}{' '}
        </div>
    );
};

export default ManageUserRolesComponent;
