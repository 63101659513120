/**
 * Created by 510004 on 2/6/2020.
 */

import React from 'react';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(2),
    },
    textField: {
        width: 200,
    },
});

const theme = createTheme({
    overrides: {
        MuiInputBase: {
            root: {
                fontSize: 'inherit',
            },
        },
    },
});

function TableSearchFieldBase(props) {
    const { classes } = props;

    return (
        <ThemeProvider theme={theme}>
            <TextField
                id="standard-search"
                type="search"
                className={clsx(classes.margin, classes.textField)}
                placeholder="Search"
                variant="filled"
                size="small"
                margin="dense"
                onChange={props.searchChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </ThemeProvider>
    );
}

TableSearchFieldBase.propTypes = {
    classes: PropTypes.object.isRequired,
    searchChange: PropTypes.func.isRequired,
    searchClick: PropTypes.func,
};

export const TableSearchField = compose(withStyles(styles))(TableSearchFieldBase);
