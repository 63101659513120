const firestoreService = {
    // Function to create a new user in Firestore with a default role
    createUser: async (user) => {
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        const authHeader = {
            Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            method: 'POST',
            headers: authHeader,
            body: JSON.stringify(user),
        };

        try {
            const response = await fetch('/api/user', requestOptions);
            if (response.status >= 200 && response.status < 300) {
                // Request was successful
                console.log('User saved to Firestore.');
            } else {
                // Server returned an error response
                console.log('Error while saving user to Firestore. HTTP Status:', response.status);
            }
        } catch (error) {
            // Handle errors (e.g., network error, server error)
            console.error('Error:', error);
        }
    },
    setCustomClaims: async (uid) => {
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        const authHeader = {
            Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            method: 'POST',
            headers: authHeader,
            body: JSON.stringify({ userId: uid }),
        };

        try {
            const response = await fetch('/api/setCustomClaims', requestOptions);
            if (response.status >= 200 && response.status < 300) {
                // Request was successful
                console.log('setCustomClaims is successful.');
            } else {
                // Server returned an error response
                console.log('Error while saving user to Firestore. HTTP Status:', response.status);
            }
        } catch (error) {
            // Handle errors (e.g., network error, server error)
            console.error('Error:', error);
        }
    },
    updateIsNewUserClaim: async (uid) => {
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        const authHeader = {
            Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            method: 'POST',
            headers: authHeader,
            body: JSON.stringify({ userId: uid }),
        };

        try {
            const response = await fetch('/api/updateIsNewUserClaim', requestOptions);
            if (response.status >= 200 && response.status < 300) {
                // Request was successful
                console.log('setCustomClaims is successful.');
            } else {
                // Server returned an error response
                console.log('Error while saving user to Firestore. HTTP Status:', response.status);
            }
        } catch (error) {
            // Handle errors (e.g., network error, server error)
            console.error('Error:', error);
        }
    },
};

export { firestoreService };
