/**
 * Created by 510004 on 1/24/2020.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';

const config = {
    mapsApiKey: 'AIzaSyAAYPUlbRlzqMEaUl2Cr0W2w9xNRcg4kew',
};

const styles = () => ({
    mapOuter: {
        height: 'calc(100vh - 160px)',
        width: '400px',
        '& div:first-child': {
            position: 'relative !important',
            height: '100% !important',
        },
    },
    responsiveImage: {
        width: '100%',
        height: 'auto !important',
    },
    imageContainer: {
        display: 'grid',
    },
});

const mapStyles = {
    width: 'calc(100vw - 250px)',
    height: 'calc(100vh - 140px)',
};

function MapContainer(props) {
    const { classes, google, lat, long } = props;

    return (
        <div id="map-outer" className={classes.mapOuter}>
            <Map google={google} zoom={8} style={mapStyles} initialCenter={{ lat: lat, lng: long }}>
                <Marker position={{ lat: lat, lng: long }} />
            </Map>
        </div>
    );
}

MapContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    coords: PropTypes.string,
    lat: PropTypes.number,
    long: PropTypes.number,
    google: PropTypes.object.isRequired,
};

export default GoogleApiWrapper({
    apiKey: config.mapsApiKey,
})(compose(withStyles(styles()))(MapContainer));
