/**
 * Created by 300126 on 2/7/2019.
 */

export const fileOperationConstants = {
    GET_DOCUMENTS_REQUEST: 'GET_DOCUMENTS_REQUEST',
    GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
    GET_DOCUMENTS_FAILURE: 'GET_DOCUMENTS_FAILURE',

    GET_FILE_LIST_REQUEST: 'GET_FILE_LIST_REQUEST',
    GET_FILE_LIST_SUCCESS: 'GET_FILE_LIST_SUCCESS',
    GET_FILE_LIST_FAILURE: 'GET_FILE_LIST_FAILURE',

    GET_FILE_REQUEST: 'GET_FILE_REQUEST',
    GET_FILE_SUCCESS: 'GET_FILE_SUCCESS',
    GET_FILE_FAILURE: 'GET_FILE_FAILURE',

    GET_RESOURCE_FILE_REQUEST: 'GET_RESOURCE_FILE_REQUEST',
    GET_RESOURCE_FILE_SUCCESS: 'GET_RESOURCE_FILE_SUCCESS',
    GET_RESOURCE_FILE_FAILURE: 'GET_RESOURCE_FILE_FAILURE',
};
