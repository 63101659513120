/**
 * Created by 510004 on 1/16/2020.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { CustomTableCell, CustomPagination, EnhancedTableHead, TableCellLink } from '../table/index';
import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { compose } from 'recompose';
import * as ROUTES from '../../_constants/routeConstants';
import { formatService, listUtilsService } from '../../_services';
import { createTheme } from '@material-ui/core/styles';
import { queryConstants } from '../../_constants';

const styles = (theme) => ({
    container: {
        display: `grid`,
        maxHeight: 'calc(100vh - 350px)',
    },
    root: {
        width: '100%',
        alignSelf: `stretch`,
        display: `grid`,
        gridTemplateRows: `1fr auto`,
    },
    table: {
        minWidth: 700,
    },
    tableBody: {
        display: 'block',
        overflowX: 'visible',
        overflowY: 'scroll',
        height: '60vh',
    },
    row: {
        height: 'auto',
    },
    tableHeadTooltip: {
        color: theme.palette.common.white,
    },
    sortLabelRoot: {
        color: theme.palette.common.white,
        '&:focus': {
            color: theme.palette.common.white,
        },
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    sortLabelActive: {
        color: theme.palette.common.white,
    },
    buttonContainer: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        position: 'relative',
    },
    disabledCell: {
        color: 'lightgray',
    },
});

const theme = createTheme({
    overrides: {
        MuiIconButton: {
            root: {
                padding: '6px 12px',
            },
        },
        MuiTablePagination: {
            toolbar: {
                minHeight: 'auto',
            },
        },
        MuiTableCell: {
            root: {
                fontSize: 'inherit',
            },
        },
    },
});

const columns = [
    {
        id: 'runIdText',
        label: 'ID',
        type: 'link',
        route: `../${ROUTES.CHEETAH_RUN}`,
        paramFields: {
            id: 'id',
            sourceType: 'sourceType',
        },
    },
    {
        id: queryConstants.CHEETAHPROJECT.HAS_PLAYBACK,
        icon: 'Insights',
        label: 'Playback',
        align: 'center',
        type: 'link',
        route: `../${ROUTES.CHEETAH_PLAYBACK}?`,
        queryParams: {
            projectId: 'projectId',
            runId: 'runIdText',
        },
    },
    {
        id: 'siId',
        icon: 'ImportantDevices',
        label: 'System',
        align: 'center',
        type: 'link',
        route: `../${ROUTES.CHEETAH_SYSTEM_INFO}?`,
        queryParams: {
            recordId: 'siId',
            sourceType: 'sourceType',
        },
    },
    {
        id: 'toolCount',
        label: 'Tool Count',
        align: 'center',
        type: 'number',
    },
    {
        id: 'softwareCheetahVersion',
        label: 'Cheetah',
        align: 'center',
    },
    {
        id: 'softwareDaqVersion',
        label: 'DAQ',
        align: 'center',
    },
    {
        id: 'startDate',
        label: 'Start Date',
        align: 'center',
        type: 'date',
        format: (value) => formatService.formatDate(value),
    },
    {
        id: 'stopDate',
        label: 'Stop Date',
        align: 'center',
        type: 'date',
        format: (value) => formatService.formatDate(value),
    },
    {
        id: 'coords',
        label: 'Location',
        align: 'center',
        type: 'link',
        route: `../${ROUTES.MAPS}?`,
        queryParams: {
            coords: 'coords',
        },
    },
];

function CheetahRunsList(props) {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('lastAccessedDate');
    const [orderType, setOrderType] = useState('date');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    // Variables.
    let currentRowsPerPage = rowsPerPage;
    let currentOrder = order;
    let currentOrderBy = orderBy;
    let currentOrderType = orderType;
    let currentPage = page;

    const handleRequestSort = (event, property) => {
        setOrderBy(property);
        currentOrderBy = property;

        const col = columns.find((column) => column.id === orderBy);
        if (col && col.type) {
            setOrderType(col.type);
            currentOrderType = col.type;
        }

        setOrder('desc');
        currentOrder = 'desc';

        if (orderBy === property && order === 'desc') {
            setOrder('asc');
            currentOrder = 'asc';
        }
    };

    const handleChangePage = (event, page) => {
        setPage(page);
        currentPage = page;
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        const rows = parseInt(event.target.value, 10);
        setRowsPerPage(rows);
        currentRowsPerPage = rows;
    };

    const { classes, runsList } = props;

    const pageSizeOptions = listUtilsService.getPageSizeOptions(runsList.length);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, runsList.length - page * rowsPerPage);

    return (
        <ThemeProvider theme={theme}>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table className={classes.table} stickyHeader aria-label="a dense sticky table" size="small">
                        <EnhancedTableHead
                            columns={columns}
                            onRequestSort={handleRequestSort}
                            order={currentOrder}
                            orderBy={currentOrderBy}
                            rowCount={runsList.length}
                        />
                        <TableBody>
                            {(rowsPerPage > 0
                                ? listUtilsService
                                      .stableSort({
                                          array: runsList,
                                          cmp: listUtilsService.getSorting({
                                              order: currentOrder,
                                              orderBy: currentOrderBy,
                                              orderType: currentOrderType,
                                          }),
                                      })
                                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : listUtilsService.stableSort({
                                      array: runsList,
                                      cmp: listUtilsService.getSorting({
                                          order: currentOrder,
                                          orderBy: currentOrderBy,
                                          orderType: currentOrderType,
                                      }),
                                  })
                            ).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row['id']}>
                                        {columns.map((column) => {
                                            const value =
                                                row[column.id] !== undefined ? row[column.id] : column['default'];
                                            const showLink =
                                                column.type &&
                                                column.type === 'link' &&
                                                value !== undefined &&
                                                value !== null;
                                            const isPlayBackLink =
                                                showLink && column.id === queryConstants.CHEETAHPROJECT.HAS_PLAYBACK;
                                            const showPlayBackLink =
                                                isPlayBackLink && row[queryConstants.CHEETAHPROJECT.HAS_PLAYBACK];

                                            return showLink ? (
                                                isPlayBackLink ? (
                                                    showPlayBackLink ? (
                                                        <TableCellLink
                                                            key={`linkcell-${column.id}-${row['id']}`}
                                                            column={column}
                                                            row={row}
                                                            value={value}
                                                        />
                                                    ) : (
                                                        <TableCell
                                                            align={column.align}
                                                            className={classes.disabledCell}
                                                            style={{ width: column.minWidth }}>
                                                            NA
                                                        </TableCell>
                                                    )
                                                ) : (
                                                    <TableCellLink
                                                        key={`linkcell-${column.id}-${row['id']}`}
                                                        column={column}
                                                        row={row}
                                                        value={value}
                                                    />
                                                )
                                            ) : (
                                                <CustomTableCell key={column.id} column={column} value={value} />
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={columns.length} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <CustomPagination
                    colSpan={columns.length}
                    count={runsList.length}
                    rowsPerPage={currentRowsPerPage}
                    page={currentPage}
                    pageSizeOptions={pageSizeOptions}
                    onRequestChangePage={handleChangePage}
                    onRequestChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </ThemeProvider>
    );
}

CheetahRunsList.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    runsList: PropTypes.array,
};

const CheetahRuns = compose(withStyles(styles))(CheetahRunsList);

export default CheetahRuns;
