/**
 * Created by 510004 on 1/15/2020.
 */

import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatService } from '../../_services';

const styles = () => ({
    fieldContainer: {
        display: 'grid',
        height: 'auto',
        maxHeight: '60px',
        gridTemplateRows: 'auto 1fr',
    },
    label: {
        fontSize: '0.8rem',
    },
    valueContainer: {
        display: 'grid',
        position: 'relative',
        transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.13)',
        },
        '&::before': {
            left: 0,
            right: 0,
            bottom: 0,
            content: '"\\00a0"',
            position: 'absolute',
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            pointerEvents: 'none',
        },
        '&::after': {
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            position: 'absolute',
            transform: 'scaleX(0)',
            transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            borderBottom: '2px solid #1976d2',
            pointerEvents: 'none',
        },
    },
    value: {
        fontSize: '0.9rem',
        margin: '8px',
        alignSelf: 'end',
    },
});

function FormFieldBase(props) {
    const { classes, label, type, value } = props;
    let formattedVal = value;

    if (type) {
        if (type === 'date') {
            formattedVal = formatService.formatDate(value);
        }
    }

    return (
        <div className={classes.fieldContainer}>
            <span className={classes.label}>{label}</span>
            <div className={classes.valueContainer}>
                <span className={classes.value}>{formattedVal}</span>
            </div>
        </div>
    );
}

FormFieldBase.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
};

const FormField = compose(withStyles(styles))(FormFieldBase);

export default FormField;
