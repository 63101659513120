/**
 * Created by 510004 on 2/20/2020.
 */

import { navConstants } from '../_constants';

export const navActions = {
    setBreadcrumbs,
    setFrom,
    setTo,
};

function setBreadcrumbs(links) {
    return (dispatch) => {
        dispatch(success(links));
    };

    function success(breadcrumbs) {
        return { type: navConstants.BREADCRUMBS, breadcrumbs };
    }
}

function setFrom(link) {
    return (dispatch) => {
        dispatch(success(link));
    };

    function success(link) {
        return { type: navConstants.FROM, link };
    }
}

function setTo(link) {
    return (dispatch) => {
        dispatch(success(link));
    };

    function success(link) {
        return { type: navConstants.TO, link };
    }
}
