/**
 * Created by 510004 on 3/4/2019.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Paper, withStyles, Grid, Button } from '@material-ui/core';
import logo from '../../static/favicons/apple-touch-icon.png';

import { withFirebase } from '../firebase';
import { AuthUserContext } from '../session';
import { compose } from 'recompose';
import * as ROUTES from '../../_constants/routeConstants';

const styles = (theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: `${theme.spacing(3)}px`,
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`,
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        padding: '15px',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(1),
    },
    toolbar: theme.mixins.toolbar,
});

const VerifyPage = () => (
    <div>
        <AuthUserContext.Consumer>
            {(authUser) => (authUser ? <VerifyForm authUser={authUser} /> : <p>{'No email found'}</p>)}
        </AuthUserContext.Consumer>
    </div>
);

function VerifyBase(props) {
    const { classes, authUser } = props;
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const onVerifyClick = () => {
        try {
            props.firebase
                .doSendEmailVerification()
                .then(() => {
                    // user needs to verify email before signing back in or maintaining current session. - reg
                    props.firebase
                        .doSignOut()
                        .then(() => {
                            navigate(`../${ROUTES.SIGN_IN}`);
                        })
                        .catch((error) => {
                            setError(error);
                        });
                })
                .catch((error) => {
                    setError(error);
                });
        } catch (err) {
            setError(err);
        }
    };

    return (
        <div className={classes.root}>
            <img className={classes.img} alt="complex" src={logo} />
            <Paper className={classes.paper}>
                <Grid container justifyContent="center" style={{ marginTop: '10px', whiteSpace: 'normal' }}>
                    <h3>Email Verification Required</h3>
                    <p>
                        Click the button below to complete the sign up process. <br /> <br />
                        An email with a verification link will be sent to this address: <b>{authUser.email}</b>
                        <br />
                        <br />
                        You will be able to log back in after you&apos;re verified.
                    </p>
                </Grid>
                <Grid container justifyContent="space-evenly" style={{ marginTop: '10px' }}>
                    <Button
                        onClick={onVerifyClick}
                        variant="outlined"
                        color="primary"
                        style={{ textTransform: 'none' }}>
                        Verify Email
                    </Button>
                </Grid>
            </Paper>
            <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                {error && <p>{error.message}</p>}
            </Grid>
        </div>
    );
}

VerifyBase.propTypes = {
    authUser: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired,
    history: PropTypes.object,
};

const VerifyForm = compose(withFirebase, withStyles(styles))(VerifyBase);

export default VerifyPage;

export { VerifyForm };
