/**
 * Created by 300126 on 2/7/2019.
 */

import { saveAs } from 'file-saver';
import { fileOperationConstants } from '../_constants';

const initialState = {
    documents: [],
    file: [],
    resourceFile: null,
    result: {},

    fetchingDocCategories: false,
    fetchingFileList: false,
    fetchingFile: false,
    fetchingResourceFile: false,
};

export function fileOperation(state = initialState, action) {
    const { documents, file, fileName, result } = action;

    switch (action.type) {
        case fileOperationConstants.GET_DOCUMENTS_REQUEST:
            return {
                ...state,
                documents: [],
                fetchingDocCategories: true,
            };
        case fileOperationConstants.GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documents: documents,
                fetchingDocCategories: false,
            };
        case fileOperationConstants.GET_DOCUMENTS_FAILURE:
            return {
                ...state,
                documents: [],
                fetchingDocCategories: false,
            };
        case fileOperationConstants.GET_FILE_LIST_REQUEST:
            return {
                ...state,
                fetchingFileList: true,
            };
        case fileOperationConstants.GET_FILE_LIST_SUCCESS:
            return {
                ...state,
                result: result,
                fetchingFileList: false,
                timestamp: Date.now(),
            };
        case fileOperationConstants.GET_FILE_LIST_FAILURE:
            return {
                ...state,
                fetchingFileList: false,
            };

        case fileOperationConstants.GET_FILE_REQUEST:
            return {
                ...state,
                fetchingFile: true,
            };
        case fileOperationConstants.GET_FILE_SUCCESS:
            saveAs(file, fileName);
            return {
                ...state,
                file: file,
                fetchingFile: false,
            };
        case fileOperationConstants.GET_FILE_FAILURE:
            return {
                ...state,
                fetchingFile: false,
            };

        case fileOperationConstants.GET_RESOURCE_FILE_REQUEST:
            return {
                ...state,
                fetchingResourceFile: true,
            };
        case fileOperationConstants.GET_RESOURCE_FILE_SUCCESS:
            return {
                ...state,
                resourceFile: file,
                fetchingResourceFile: false,
            };
        case fileOperationConstants.GET_RESOURCE_FILE_FAILURE:
            return {
                ...state,
                resourceFile: null,
                fetchingResourceFile: false,
            };

        default:
            return state;
    }
}
