/**
 * Created by 510004 on 2/20/2019.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { compose } from 'recompose';

import { Paper, withStyles, Grid, TextField, Button, FormControl } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons';
import logo from '../../static/favicons/apple-touch-icon.png';

import { PasswordForgetLink } from '../passwordForget';
import { withFirebase } from '../firebase';
import * as ROUTES from '../../_constants/routeConstants';
import { SignUpLink } from '../signup';
import { OAuthProvider } from 'firebase/auth';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { getAdditionalUserInfo } from 'firebase/auth';
import { firestoreService } from '../../_services';
const provider = new OAuthProvider('microsoft.com');

const styles = (theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: `${theme.spacing(3)}px`,
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`,
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    image: {
        width: 64,
        height: 64,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        padding: '15px',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(1),
    },
    toolbar: theme.mixins.toolbar,
});

const SignInPage = () => <SignInForm />;

function SignInFormBase(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [value, setValue] = React.useState('2');
    const email_login_btn_name = 'emailSubmitButton';
    const altus_login_btn_name = 'altusSubmitButton';
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate();
    provider.setCustomParameters({
        // Optional "tenant" parameter in case you are using an Azure AD tenant.
        // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
        // or "common" for tenant-independent tokens.
        // The default value is "common".
        tenant: '7d306d8d-68d6-4556-b48d-4131cab272a8',
    });

    const onSubmit = (event) => {
        if (event.nativeEvent.submitter.name === altus_login_btn_name) {
            props.firebase
                .doSignInWithPopup(provider)
                .then(async (result) => {
                    // User is signed in.
                    // IdP data available in result.additionalUserInfo.profile.

                    // Get the OAuth access token and ID Token
                    // const credential = OAuthProvider.credentialFromResult(result);
                    //const accessToken = credential.accessToken;
                    //const idToken = credential.idToken;
                    const isFirstLogin = getAdditionalUserInfo(result.credential).isNewUser;
                    if (isFirstLogin) {
                        await firestoreService.createUser(props.firebase, result.user.uid);
                    }
                    if (result.credential) navigate(`../${ROUTES.CHEETAH_PROJECTS}`);
                })
                .catch((error) => {
                    setError(error);
                });
        } else {
            props.firebase
                .doSignInWithEmailAndPassword(email, password)
                .then(async (credential) => {
                    const isFirstLogin = getAdditionalUserInfo(credential).isNewUser;
                    const idTokenResult = await credential.user.getIdTokenResult(true);
                    if (isFirstLogin) {
                        await firestoreService.createUser(credential.user);
                    } else {
                        await firestoreService.setCustomClaims(credential.user.uid);
                        await credential.user.getIdToken(true);
                    }

                    if (credential.user.emailVerified) {
                        let route = ROUTES.CHEETAH_PROJECTS;
                        if (idTokenResult?.claims?.isClientView) {
                            route = ROUTES.CHEETAH_WEB;
                        }
                        navigate(`../${route}`);

                        window.location.reload();
                    } else {
                        if (idTokenResult?.claims?.isClientView && idTokenResult.claims.isNewUser) {
                            return navigate(`/${ROUTES.PASSWORD_FORGET}`);
                        }
                        setError('Please verify your email address');
                        navigate(`../${ROUTES.VERIFY}`);
                    }
                })
                .catch((error) => {
                    setError(error);
                });
        }

        event.preventDefault();
    };

    const onChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
            return;
        }

        if (name === 'password') {
            setPassword(value);
            return;
        }

        if (name === 'error') {
            setError(value);
        }
    };

    const { classes } = props;
    const isInvalid = password === '' || email === '';

    const errorObj =
        error && error.message ? (error.message.startsWith('{') ? JSON.parse(error.message) : error) : null;

    return (
        <form onSubmit={onSubmit}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <img className={classes.img} alt="complex" src={logo} />
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    {/*<Tab label="Altus AD" value="1" />*/}
                                    <Tab label="Other Email" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="2">
                                <Paper className={classes.paper}>
                                    <Grid container justifyContent="center" spacing={10} alignItems="flex-end">
                                        <Grid item lg={2}>
                                            <Face />
                                        </Grid>
                                        <Grid item lg={8}>
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    id="username"
                                                    label="Username"
                                                    type="email"
                                                    name="email"
                                                    fullWidth
                                                    value={email}
                                                    onChange={onChange}
                                                    autoFocus
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center" spacing={10} alignItems="flex-end">
                                        <Grid item lg={2}>
                                            <Fingerprint />
                                        </Grid>
                                        <Grid item lg={8}>
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    id="password"
                                                    label="Password"
                                                    type="password"
                                                    name="password"
                                                    fullWidth
                                                    value={password}
                                                    onChange={onChange}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <PasswordForgetLink />
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <SignUpLink />
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ textTransform: 'none' }}
                                            name={email_login_btn_name}
                                            disabled={isInvalid}
                                            type="submit">
                                            Login
                                        </Button>
                                    </Grid>
                                </Paper>
                                <Grid container justifyContent="center" style={{ marginTop: '10px', color: 'red' }}>
                                    {errorObj ? (
                                        errorObj.error ? (
                                            <p>{`${errorObj.error.status}`}</p>
                                        ) : (
                                            <p>{errorObj.message}</p>
                                        )
                                    ) : (
                                        <p />
                                    )}
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Paper>
            </div>
        </form>
    );
}

SignInFormBase.propTypes = {
    classes: PropTypes.object.isRequired,
    email: PropTypes.string,
    firebase: PropTypes.object,
    history: PropTypes.object,
    password: PropTypes.string,
};

const SignInForm = compose(withFirebase, withStyles(styles))(SignInFormBase);

export default SignInPage;

export { SignInForm };
