/**
 * Created by 510004 on 1/14/2020.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import FormField from '../formField';
import { compose } from 'recompose';
import ToolInformation from '../toolInformation';

const styles = () => ({
    formContainer: {
        display: 'grid',
        height: '100%',
        gridTemplateRows: 'auto 1fr',
    },
    fieldsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 300px))',
        gridAutoRows: '1fr',
        padding: '10px',
        gridGap: '10px',
    },
    runsContainer: {
        display: 'grid',
        gridGap: '10px',
        alignSelf: `stretch`,
        gridTemplateRows: `auto 1fr`,
    },
    fieldContainer: {
        display: 'grid',
    },
    runsHeaderText: {
        fontSize: 'inherit',
        fontWeight: '600',
    },
});

const fields = [
    { id: 'runIdText', label: 'Run ID' },
    { id: 'startDate', label: 'Started', type: 'date' },
    { id: 'stopDate', label: 'Stopped', type: 'date' },
    { id: 'sessionIdText', label: 'Session ID' },
];

function CheetahRunBase(props) {
    const { classes, cheetahRun } = props;

    cheetahRun.toolInformation = cheetahRun.toolInformation || [];

    return (
        <div className={classes.formContainer}>
            <div className={classes.fieldsContainer}>
                {fields.map((field) => {
                    return (
                        <FormField key={field.id} label={field.label} value={cheetahRun[field.id]} type={field.type} />
                    );
                })}
            </div>
            <div className={classes.runsContainer}>
                <div className={classes.runsHeaderText}>Toolstring Info</div>
                <ToolInformation toolInformationList={cheetahRun.toolInformation} />
            </div>
        </div>
    );
}

CheetahRunBase.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    cheetahRun: PropTypes.object,
};

const CheetahRun = compose(withStyles(styles))(CheetahRunBase);

export default CheetahRun;
