/**
 * Created by 510004 on 2/26/2019.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import { Paper, withStyles, Grid, TextField, Button, FormControl } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons';
import logo from '../../static/favicons/apple-touch-icon.png';

import { withFirebase } from '.././firebase';
import * as ROUTES from '../../_constants/routeConstants';
import { compose } from 'recompose';
import { userActions } from '../../_actions/userActions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getAdditionalUserInfo } from 'firebase/auth';
import { firestoreService } from '../../_services';

const styles = (theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: `${theme.spacing(3)}px`,
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`,
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        padding: '15px',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(1),
    },
    toolbar: theme.mixins.toolbar,
});

const SignUpPage = () => (
    <div>
        <SignUpForm />
    </div>
);

function SignUpFormBase(props) {
    const navigate = useNavigate();
    const { classes, dispatch, domains } = props;

    const [email, setEmail] = useState('');
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        dispatch(userActions.getValidDomains());
    }, [dispatch]);

    const onSubmit = (event) => {
        props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(async (userCredential) => {
                setEmail('');
                setPasswordOne('');
                setPasswordTwo('');
                setError(null);
                const isFirstLogin = getAdditionalUserInfo(userCredential).isNewUser;
                if (isFirstLogin) {
                    await firestoreService.createUser(userCredential.user);
                }
                navigate(`../${ROUTES.VERIFY}`);
            })
            .catch((error) => {
                setError(error);
            });

        event.preventDefault();
    };

    const isEmailInvalid = (email) => {
        let isInvalid = true;

        if (_.isNil(domains)) {
            return isInvalid;
        }

        if (email.length > 0 && email.indexOf('@') > 0) {
            const domain = email.split('@')[1];
            isInvalid = domains.indexOf(domain) < 0;
        }

        return isInvalid;
    };

    const onChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
            return;
        }

        if (name === 'passwordOne') {
            setPasswordOne(value);
            return;
        }

        if (name === 'passwordTwo') {
            setPasswordTwo(value);
            return;
        }

        if (name === 'error') {
            setError(value);
        }
    };

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || isEmailInvalid(email);

    return (
        <form onSubmit={onSubmit}>
            <div className={classes.root}>
                <img className={classes.img} alt="complex" src={logo} />
                <Paper className={classes.paper}>
                    <Grid container justifyContent="center" spacing={10} alignItems="flex-end">
                        <Grid item lg={2}>
                            <Face />
                        </Grid>
                        <Grid item lg={8}>
                            <FormControl fullWidth={true}>
                                <TextField
                                    id="email"
                                    label="Email"
                                    type="email"
                                    name="email"
                                    fullWidth
                                    error={email.length > 0 && isEmailInvalid(email)}
                                    helperText={
                                        email.length > 0 && isEmailInvalid(email)
                                            ? 'Should be a company email address'
                                            : ''
                                    }
                                    value={email}
                                    onChange={onChange}
                                    autoFocus
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={10} alignItems="flex-end">
                        <Grid item lg={2}>
                            <Fingerprint />
                        </Grid>
                        <Grid item lg={8}>
                            <FormControl fullWidth={true}>
                                <TextField
                                    id="passwordOne"
                                    label="Password"
                                    type="password"
                                    name="passwordOne"
                                    fullWidth
                                    value={passwordOne}
                                    onChange={onChange}
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={10} alignItems="flex-end">
                        <Grid item lg={2}>
                            <Fingerprint />
                        </Grid>
                        <Grid item lg={8}>
                            <FormControl fullWidth={true}>
                                <TextField
                                    id="passwordTwo"
                                    label="Repeat Password"
                                    type="password"
                                    name="passwordTwo"
                                    fullWidth
                                    value={passwordTwo}
                                    onChange={onChange}
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-evenly" style={{ marginTop: '10px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ textTransform: 'none' }}
                            disabled={isInvalid}
                            type="submit">
                            Sign Up
                        </Button>
                    </Grid>
                </Paper>
                <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                    {error && <p>{error.message}</p>}
                </Grid>
            </div>
        </form>
    );
}

SignUpFormBase.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    domains: PropTypes.array,
    email: PropTypes.string,
    firebase: PropTypes.object,
    history: PropTypes.object,
    password: PropTypes.string,
};

const SignUpLink = () => (
    <p>
        New User? <Link to={`../${ROUTES.SIGN_UP}`}>Sign Up</Link>
    </p>
);

function mapStateToProps(state) {
    const { domains } = state.users;
    return {
        domains,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
    };
};

const SignUpForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFirebase,
    withStyles(styles)
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
