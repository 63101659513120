/**
 * Created by 510004 on 1/24/2020.
 */

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { dataActions, navActions } from '../_actions';
import { withAuthorization } from '../components/session';
import CheetahSystemInfo from '../components/cheetahSystemInfo';
import CustomBreadcrumbs from '../components/breadcrumbs';
import * as ROUTES from '../_constants/routeConstants';
import _ from 'lodash';

const TITLE = 'Cheetah System Information';

const styles = (theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        height: '100%',
        width: '100%',
    },
    fieldsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        padding: '10px',
        gridGap: '10px',
    },
    fieldContainer: {
        display: 'grid',
    },
    formContainer: {
        display: 'grid',
        height: '100%',
        gridTemplateRows: 'auto auto 1fr',
        padding: '20px',
    },
    header: {
        alignItems: 'baseline',
        display: 'grid',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: '1.25rem',
        fontWeight: '700',
    },
    runsHeaderText: {
        fontSize: 'inherit',
        fontWeight: '600',
    },
});

function CheetahSystemInfoView(props) {
    const { classes } = props;
    const [searchParams] = useSearchParams();
    const recordId = searchParams.get('recordId');
    const sourceType = searchParams.get('sourceType');
    const dispatch = useDispatch();

    const cheetahSystemInfo = useSelector((state) => getCheetahSystemInfo(state));
    const { navOperation } = useSelector((state) => state);

    useEffect(() => {
        dispatch(dataActions.getSystemInfoRecord({ recordId: recordId, sourceType: sourceType }));

        return function cleanup() {
            dispatch(dataActions.resetRecord({ recordType: 'cheetahSystemInfo' }));
        };
    }, [recordId]);

    useEffect(() => {
        if (!_.isNil(cheetahSystemInfo) && cheetahSystemInfo.id === parseInt(recordId) && !_.isNil(recordId)) {
            const { from, to } = navOperation;
            const target = {
                title: cheetahSystemInfo.id,
                url: `../${ROUTES.CHEETAH_SYSTEM_INFO}${recordId}${sourceType}`,
            };
            const orderedLinks = [from, to];

            dispatch(navActions.setBreadcrumbs(orderedLinks));
            dispatch(navActions.setTo(target));
        }
    }, [cheetahSystemInfo]);

    const resolvedSystemInfo = cheetahSystemInfo ?? {};

    return (
        <div className={classes.formContainer}>
            <CustomBreadcrumbs />
            <div className={classes.header}>
                <span className={classes.headerText}>{TITLE}</span>
            </div>
            <CheetahSystemInfo cheetahSystemInfo={resolvedSystemInfo} />
        </div>
    );
}

function getCheetahSystemInfo(state) {
    const { cheetahSystemInfo } = state.dataOperation;
    if (_.isNil(cheetahSystemInfo)) {
        return {};
    }

    return cheetahSystemInfo;
}

CheetahSystemInfoView.propTypes = {
    classes: PropTypes.object.isRequired,
};

const condition = (authUser) => !!authUser;

export default withStyles(styles)(withAuthorization(condition)(CheetahSystemInfoView));
