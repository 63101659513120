/**
 * Created by 300126 on 2/7/2019.
 */

import { fileOperationConstants } from '../_constants';
import { fetchService } from '../_services';
import { alertActions } from './';
import _ from 'lodash';

export const fileOperationActions = {
    getDocuments,
    getFileList,
    getFile,
    getResourceFile,
};

function getDocuments({ version }) {
    return async (dispatch) => {
        try {
            dispatch(request());
            const documents = await fetchService.fetchDocuments({ version: version });
            dispatch(success(documents));
        } catch (error) {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
    };

    function request() {
        return { type: fileOperationConstants.GET_DOCUMENTS_REQUEST };
    }
    function success(documents) {
        return { type: fileOperationConstants.GET_DOCUMENTS_SUCCESS, documents };
    }
    function failure(error) {
        return { type: fileOperationConstants.GET_DOCUMENTS_FAILURE, error };
    }
}

function getFileList({
    refresh = false,
    first,
    afterCursor,
    last,
    beforeCursor,
    order,
    orderBy,
    orderType,
    searchValue,
    searchField,
}) {
    return async (dispatch) => {
        dispatch(request());

        afterCursor = !_.isNil(first) && _.isNil(afterCursor) ? 'null' : afterCursor;
        beforeCursor = !_.isNil(last) && _.isNil(beforeCursor) ? 'null' : beforeCursor;

        const result = await fetchService.fetchFileList({
            refresh: refresh,
            first: first,
            afterCursor: afterCursor,
            last: last,
            beforeCursor: beforeCursor,
            order: order,
            orderBy: orderBy,
            orderType: orderType,
            searchValue: searchValue,
            searchField: searchField,
        });

        const { hasErrors, isEmpty, errors, cheetahLogs } = result;
        if (hasErrors) {
            dispatch(failure(errors.toString()));
            dispatch(alertActions.error(errors.toString()));
            return;
        }

        if (isEmpty) {
            dispatch(failure('getFileList: Is empty payload.'));
            return;
        }

        if (!_.isNil(cheetahLogs)) {
            dispatch(success(cheetahLogs));
            return;
        }

        dispatch(failure(`getFileList: Unrecognized payload: ${JSON.stringify(result)}.`));
    };

    function request() {
        return { type: fileOperationConstants.GET_FILE_LIST_REQUEST };
    }
    function success(result) {
        return { type: fileOperationConstants.GET_FILE_LIST_SUCCESS, result: result };
    }
    function failure(error) {
        return { type: fileOperationConstants.GET_FILE_LIST_FAILURE, error };
    }
}

function getFile({ authUser, fileName }) {
    return async (dispatch) => {
        dispatch(request());

        try {
            const file = await fetchService.fetchFile({ authUser: authUser, fileName: fileName });
            dispatch(success(file, fileName));
        } catch (err) {
            dispatch(failure(err.toString()));
            dispatch(alertActions.error(err.toString()));
        }
    };

    function request() {
        return { type: fileOperationConstants.GET_FILE_REQUEST };
    }
    function success(file, fileName) {
        return { type: fileOperationConstants.GET_FILE_SUCCESS, file, fileName };
    }
    function failure(error) {
        return { type: fileOperationConstants.GET_FILE_FAILURE, error };
    }
}

function getResourceFile({ version, fileName }) {
    return async (dispatch) => {
        try {
            dispatch(request());
            const file = await fetchService.fetchResourceFile({ version: version, fileName: fileName });
            dispatch(success(file));
        } catch (error) {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
    };

    function request() {
        return { type: fileOperationConstants.GET_RESOURCE_FILE_REQUEST };
    }
    function success(file) {
        return { type: fileOperationConstants.GET_RESOURCE_FILE_SUCCESS, file };
    }
    function failure(error) {
        return { type: fileOperationConstants.GET_RESOURCE_FILE_FAILURE, error };
    }
}
