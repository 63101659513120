/**
 * Created by 510004 on 2/20/2020.
 */

export const navConstants = {
    BREADCRUMBS: 'BREADCRUMBS',
    FROM: 'FROM',
    TO: 'TO',
    DEFAULT: 'DEFAULT',
    SIDEBAROPEN: 'SIDEBAROPEN',
    SIDEBARCLOSE: 'SIDEBARCLOSE',
};
