/**
 * Created by 510004 on 3/8/2019.
 */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import * as ROUTES from '../../_constants/routeConstants';
import { Paper, withStyles, Grid } from '@material-ui/core';
import logo from '../../static/favicons/apple-touch-icon.png';
import { AuthUserContext } from '../session';
import _ from 'lodash';

const styles = (theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: `${theme.spacing(3)}px`,
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`,
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        padding: '15px',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(1),
    },
});

const Landing = () => (
    <div>
        <AuthUserContext.Consumer>{(authUser) => <LandingPage authUser={authUser} />}</AuthUserContext.Consumer>
    </div>
);

function LandingPageBase(props) {
    const navigate = useNavigate();

    useEffect(() => {
        const { authUser } = props;

        if (!_.isNil(authUser) && authUser.emailVerified) {
            let route = ROUTES.CHEETAH_PROJECTS;
            const claims = JSON.parse(localStorage.getItem('userClaims'));

            if (claims?.isClientView) {
                route = ROUTES.CHEETAH_WEB;
            }

            // if (authUser.claims?.isClientView) {
            //     route = ROUTES.CHEETAH_WEB;
            // }

            navigate(`../${route}`);

            //navigate(ROUTES.CHEETAH_PROJECTS);
            // console.log(`LandingPageBase.componentDidMount - pushed [${ROUTES.CHEETAH_PROJECTS}] into history.`);
            return;
        }

        navigate(ROUTES.SIGN_IN);
    }, [props]);

    const { classes } = props;

    return (
        <div className={classes.root}>
            <img className={classes.img} alt="complex" src={logo} />
            <Paper className={classes.paper}>
                <Grid container justifyContent="center" spacing={10} alignItems="flex-end" />
                <Grid container justifyContent="center" style={{ marginTop: '10px' }} />
            </Paper>
        </div>
    );
}

LandingPageBase.propTypes = {
    authUser: PropTypes.object,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object,
};

const LandingPage = compose(withStyles(styles))(LandingPageBase);

Landing.propTypes = {
    authUser: PropTypes.object,
};

export default Landing;
