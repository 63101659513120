/**
 * Created by 510004 on 1/14/2020.
 */

const CHEETAH_PROJECT = {
    HAS_TOOLS: 'hasTools',
    LAST_ACCESSED_DATE: 'lastAccessedDate',
    PROJECT_CREATED_DATE: 'projectCreatedDate',
    PROJECT_NAME: 'projectName',
    RUN_COUNT: 'runCount',
    SOFTWARE_CHEETAH_VERSION: 'softwareCheetahVersion',
    HAS_PLAYBACK: 'hasPlayback',
    SOURCE_TYPE: 'sourceType',
};

const CHEETAHLOGFIELDS = `name, ${CHEETAH_PROJECT.PROJECT_NAME}, size, timeCreated, ip, projectId, companyId`;
const CHEETAHPROJECTFIELDS = `id, ${CHEETAH_PROJECT.PROJECT_NAME}, ${CHEETAH_PROJECT.PROJECT_CREATED_DATE}, ${CHEETAH_PROJECT.LAST_ACCESSED_DATE}`;
const CHEETAHPROJECTSFIELDS = `id, ${CHEETAH_PROJECT.PROJECT_NAME}, ${CHEETAH_PROJECT.PROJECT_CREATED_DATE}, ${CHEETAH_PROJECT.LAST_ACCESSED_DATE}, ${CHEETAH_PROJECT.RUN_COUNT}, ${CHEETAH_PROJECT.HAS_TOOLS}, ${CHEETAH_PROJECT.HAS_PLAYBACK}, ${CHEETAH_PROJECT.SOURCE_TYPE}, ${CHEETAH_PROJECT.SOFTWARE_CHEETAH_VERSION}, companyId`;
const CHEETAHRUNFIELDS = `id, runIdText, startDate, stopDate, sessionIdText, cheetahSessionId,sourceType`;
const CHEETAHRUNSFIELDS = `id, runIdText, startDate, stopDate, serviceName, coords, cheetahSessionIdText, projectId, siId, softwareCheetahVersion, softwareDaqVersion, toolCount, hasPlayback,sourceType`;
const CHEETAHTOOLINFORMATIONFIELDS = `id, serialNumber, firmwareId, firmwareRevision, altusId, toolId, hardwareId, hardwareRevision, leinRevision, caption, timestamp`;
const PAGEINFOFIELDS = `startCursor, hasNextPage`;

export const queryConstants = {
    CHEETAHPROJECT: CHEETAH_PROJECT,
    CHEETAHLOGFIELDS: CHEETAHLOGFIELDS,
    CHEETAHTOOLINFORMATIONFIELDS: CHEETAHTOOLINFORMATIONFIELDS,
    CHEETAHPROJECTFIELDS: `${CHEETAHPROJECTFIELDS}, runs { ${CHEETAHRUNSFIELDS} }`,
    CHEETAHPROJECTSFIELDS: CHEETAHPROJECTSFIELDS,
    CHEETAHRUNFIELDS: `${CHEETAHRUNFIELDS}, toolInformation { ${CHEETAHTOOLINFORMATIONFIELDS} }`,
    CHEETAHSYSTEMINFORMATIONFIELDS:
        'id, projectId, loginUsername, softwareDaqVersion, softwareStartTime, logStartTime, os,' +
        'osPlatform, osDistro, osArch, osHostname, osSerial, osRelease, systemManufacturer, systemModel, systemSerial, systemUuid,' +
        'systemSku, timeTimezoneOffset, timeTimezoneName, timeTimezone, timeLocale, softwareCheetahVersion, softwareElectronVersion,' +
        'cheetahSessionId, cheetahSessionIdText',
    GETCHEETAHPROJECTS: `cheetahProjects{ ${CHEETAHPROJECTSFIELDS} }`,
    GETVERSIONS: 'versions',
    QUERYCHEETAHSYSTEMINFORECORD: 'cheetahSystemInformation',
    QUERYCHEETAHPROJECTSPAGED: 'cheetahProjectsPaged',
    QUERYCURSORFIELDS: `totalCount, edges, pageInfo`,
    QUERYCHEETAHLOGSCURSORFIELDS: `totalCount, edges { node {${CHEETAHLOGFIELDS}} }, pageInfo {${PAGEINFOFIELDS}}`,
    QUERYCHEETAHPROJECTSCURSORFIELDS: `totalCount, edges { node {${CHEETAHPROJECTSFIELDS}} }, pageInfo {${PAGEINFOFIELDS}}`,
};
