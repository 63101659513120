/**
 * Created by 510004 on 2/20/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { AuthUserContext } from '../session';
import Link from '@material-ui/core/Link';
import { AppBar, withStyles, Toolbar, Typography } from '@material-ui/core';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import SignOutButton from '../signout';
import { appConstants, navConstants } from '../../_constants';
import logo from '../../static/favicons/apple-touch-icon.png';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
const styles = (theme) => ({
    appBar: {
        position: 'static',
        backgroundColor: '#105F9A',
        color: theme.palette.common.white,
    },
    toolBar: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        [theme.breakpoints.down('lg')]: {
            minHeight: '44px',
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: '44px',
        },
    },
    img: {
        background: 'white',
        borderRadius: '2px',
        marginRight: '10px',
        padding: '5px',
        height: '24px',
        width: '24px',
    },
    email: {
        color: 'inherit',
        fontSize: 'inherit',
        marginRight: '10px',
        width: 'auto',
        textAlign: 'end',
    },
    title: {
        color: 'inherit',
        fontSize: 'inherit',
        flexGrow: 1,
    },
    titleContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridAutoFlow: 'column',
        color: 'white',
        '&:hover': {
            color: 'white',
            textDecoration: 'none',
        },
    },
    navLinksGroup: {
        display: 'flex',
        flexDirection: 'row',
    },
    imgTitle: {
        verticalAlign: 'super',
    },
});
const Navigation = () => (
    <AuthUserContext.Consumer>
        {(authUser) =>
            authUser && authUser.emailVerified ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />
        }
    </AuthUserContext.Consumer>
);
const drawerWidth = 170;
function NavigationAuthBase(props) {
    // const [open, setOpen] = useState(false);
    const { classes, authUser } = props;
    let { sidebarMenuState } = props;
    let open = sidebarMenuState == navConstants.DEFAULT || sidebarMenuState == navConstants.SIDEBAROPEN ? true : false;

    // eslint-disable-next-line react/display-name
    const renderLink = React.forwardRef((linkProps, ref) => <Link ref={ref} href="/" {...linkProps} />);

    const handleDrawerOpen = () => {
        console.log('handleDrawerOpen');
        props.handleMenuClick();
        //setOpen(true);
    };

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    return (
        <AppBar position="fixed" open={open} sx={{ backgroundColor: '#105F9A' }}>
            <Toolbar className={classes.toolBar}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: '10px',
                        ...(open && { display: 'none' }),
                    }}>
                    <MenuIcon />
                </IconButton>
                <Typography className={classes.titleContainer} component={renderLink}>
                    <span>
                        <img className={classes.img} alt="complex" src={logo} />
                        <span className={classes.imgTitle}>{appConstants.TITLE}</span>
                    </span>
                </Typography>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Typography className={classes.email}>
                        {authUser.email}
                        <SignOutButton />
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

NavigationAuthBase.propTypes = {
    authUser: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    firebase: PropTypes.object,
    handleMenuClick: PropTypes.func,
    sidebarMenuState: PropTypes.string.isRequired,
};

export const NavigationAuth = withStyles(styles)(NavigationAuthBase);

function NavigationNonAuthBase(props) {
    const { classes } = props;

    // eslint-disable-next-line react/display-name
    const renderLink = React.forwardRef((linkProps, ref) => <Link ref={ref} href="/" {...linkProps} />);

    return (
        <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
                <Typography className={classes.titleContainer} component={renderLink}>
                    <img className={classes.img} alt="complex" src={logo} />
                    {appConstants.TITLE}
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

NavigationNonAuthBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

export const NavigationNonAuth = withStyles(styles)(NavigationNonAuthBase);

Navigation.propTypes = {
    authUser: PropTypes.object,
};

export default Navigation;
