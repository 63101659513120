/**
 * Created by 700290 on 6/23/2023.
 */
export const configService = {
    getAuthConfig,
    getCheetahWebConfig,
    checkIfCheetahIsOnline,
};
async function getCheetahWebConfig() {
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    const authHeader = { Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken };
    const requestOptions = {
        method: 'GET',
        headers: authHeader,
    };

    return await fetch(`/api/config/cheetahWebConfig`, requestOptions).then(handleResponse);
}

async function getAuthConfig() {
    const requestOptions = {
        method: 'GET',
    };

    return await fetch(`/api/config/authConfig`, requestOptions).then(handleResponse);
}

async function checkIfCheetahIsOnline() {
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    const cheetahConfig = await getCheetahWebConfig();
    const cheetahwebUrl = cheetahConfig.url + '/is-cheetah-online' + '?token=' + authUser.stsTokenManager.accessToken;

    const requestOptions = {
        method: 'POST',
        mode: 'no-cors',
    };

    return await fetch(cheetahwebUrl, requestOptions)
        .then((response) => {
            return response.status < 400 || response.status >= 500;
        })
        .catch((error) => {
            console.log('Cheetah web online check: ' + error);
            return false;
        });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
