/**
 * Created by 510004 on 2/4/2020.
 */
import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { CustomTableHeaderCell } from './customTableHeaderCell';

const styles = (theme) => ({
    tableHeadTooltip: {
        color: theme.palette.common.white,
    },
    sortLabelRoot: {
        color: theme.palette.common.white,
        '&:focus': {
            color: theme.palette.common.white,
        },
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    sortLabelActive: {
        color: theme.palette.common.white,
    },
});

function EnhancedTableHeadBase(props) {
    const handleRequestSort = (event, property) => {
        props.onRequestSort(event, property);
    };

    const { columns, order, orderBy } = props;
    const headerColumns = columns || [];

    return (
        <TableHead>
            <TableRow>
                {headerColumns.map(
                    (column) => (
                        <CustomTableHeaderCell
                            key={column.id}
                            column={column}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                    ),
                    this
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHeadBase.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export const EnhancedTableHead = compose(withStyles(styles))(EnhancedTableHeadBase);
