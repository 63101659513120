/**
 * Created by 510004 on 1/16/2020.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, Paper } from '@material-ui/core';
import { withAuthorization } from '../components/session';
import playback from './../static/images/image1.png';
// import { useParams } from 'react-router-dom';

const TITLE = 'Playback';

const styles = (theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        height: '100%',
        width: '100%',
    },
    responsiveImage: {
        width: '100%',
        height: 'auto !important',
    },
    imageContainer: {
        display: 'grid',
        padding: '10px',
    },
    header: {
        alignItems: 'baseline',
        display: 'grid',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: '1.25rem',
        fontWeight: '700',
    },
    runsHeaderText: {
        fontSize: 'inherit',
        fontWeight: '600',
    },
});

function PlaybackViewer(props) {
    // const params = useParams();  // todo: re-enable when this page is ready for production. - reg.
    // const currentId = params.id;
    const { classes } = props;

    return (
        <Paper className={classes.paper}>
            <div className={classes.formContainer}>
                <div className={classes.header}>
                    <span className={classes.headerText}>{TITLE}</span>
                </div>
                <div className={classes.imageContainer}>
                    <img className={classes.responsiveImage} alt="Playback" src={playback} />
                </div>
            </div>
        </Paper>
    );
}

const mapStateToProps = (state) => {
    const { imageBuffer, timestamp } = state.dataOperation;
    return {
        imageBuffer,
        timestamp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        onRefreshClick: () => {
            // dispatch(dataActions.getImage({ imageId: 'image1.png' }));
        },
    };
};

PlaybackViewer.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    imageBuffer: PropTypes.array,
    timestamp: PropTypes.number,
    onRefreshClick: PropTypes.func,
};

const condition = (authUser) => !!authUser;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(withAuthorization(condition)(PlaybackViewer)));
