/**
 * Created by 510004 on 2/21/2019.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AuthUserContext from './context';
import { withFirebase } from '../firebase';
import _ from 'lodash';

const withAuthentication = (Component) => {
    function WithAuthentication(props) {
        const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem('authUser')));
        let listener;

        const componentCleanup = () => {
            if (!_.isNil(listener)) {
                listener();
            }
        };

        useEffect(() => {
            listener = props.firebase.auth.onAuthStateChanged(
                (authUser) => {
                    if (authUser !== null && authUser !== undefined) {
                        let customClaims = {};
                        authUser
                            .getIdTokenResult()
                            .then((idTokenResult) => {
                                customClaims = idTokenResult.claims;
                                // const user = {
                                //     ...authUser,
                                //     claims: customClaims,
                                // };
                                const user = _.cloneDeep(authUser);
                                user.claims = customClaims;

                                localStorage.removeItem('authUser');
                                localStorage.removeItem('userClaims');

                                localStorage.setItem('authUser', JSON.stringify(user));
                                localStorage.setItem('userClaims', JSON.stringify(customClaims));

                                setAuthUser(user);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        localStorage.removeItem('userClaims');

                        localStorage.setItem('authUser', JSON.stringify(authUser));
                        setAuthUser(authUser);
                    }
                },
                () => {
                    localStorage.removeItem('authUser');
                    localStorage.removeItem('userClaims');

                    setAuthUser(null);
                }
            );

            window.addEventListener('beforeunload', componentCleanup);

            return () => {
                if (!_.isNil(listener)) {
                    listener();
                }
                window.removeEventListener('beforeunload', componentCleanup);
            };
        }, [props]);

        return (
            <AuthUserContext.Provider value={authUser}>
                <Component {...props} />
            </AuthUserContext.Provider>
        );
    }

    WithAuthentication.propTypes = {
        firebase: PropTypes.object,
    };

    return withFirebase(WithAuthentication);
};

export default withAuthentication;
