/**
 * Created by 510004 on 2/19/2020.
 */

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { dataActions, navActions } from '../_actions';
import { withAuthorization } from '../components/session';
import CheetahRun from '../components/cheetahRun';
import CustomBreadcrumbs from '../components/breadcrumbs';
import * as ROUTES from '../_constants/routeConstants';
// import { store } from '../_helpers';
import _ from 'lodash';

const TITLE = 'Cheetah Run';

const styles = (theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        height: '100%',
        width: '100%',
    },
    fieldsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        padding: '10px',
        gridGap: '10px',
    },
    fieldContainer: {
        display: 'grid',
    },
    formContainer: {
        display: 'grid',
        height: '100%',
        gridTemplateRows: 'auto auto 1fr',
        padding: '20px',
    },
    header: {
        alignItems: 'baseline',
        display: 'grid',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: '1.25rem',
        fontWeight: '700',
    },
    runsHeaderText: {
        fontSize: 'inherit',
        fontWeight: '600',
    },
});

function CheetahRunView(props) {
    const params = useParams();
    const currentId = params.id;
    const source = params.sourceType;
    const { classes } = props;
    const dispatch = useDispatch();

    const cheetahRun = useSelector((state) => getCheetahRun(state));
    const { navOperation } = useSelector((state) => state);

    function fetchCheetahRun() {
        //dispatch(dataActions.getRecord({ type: 'cheetahRun', recordId: currentId }));
        dispatch(dataActions.getRecord({ type: 'cheetahRun', recordId: currentId, sourceType: source }));
    }

    useEffect(() => {
        if (_.isNil(currentId)) {
            return;
        }

        fetchCheetahRun();

        return function cleanup() {
            dispatch(dataActions.resetRecord({ recordType: 'cheetahRun' }));
        };
    }, [currentId]);

    const resolvedRun = cheetahRun || {};
    resolvedRun.runs = resolvedRun.runs || [];

    useEffect(() => {
        if (!_.isNil(cheetahRun) && cheetahRun.id === parseInt(currentId) && !_.isNil(cheetahRun.runIdText)) {
            const { from, to } = navOperation;

            const target = { title: cheetahRun.runIdText, url: `../${ROUTES.CHEETAH_RUN}${currentId}` };
            const orderedLinks = [from, to];

            dispatch(navActions.setBreadcrumbs(orderedLinks));
            dispatch(navActions.setTo(target));
        }
    }, [cheetahRun]);

    return (
        <div className={classes.formContainer}>
            <CustomBreadcrumbs />
            <div className={classes.header}>
                <span className={classes.headerText}>{TITLE}</span>
            </div>
            <CheetahRun cheetahRun={resolvedRun} />
        </div>
    );
}

function getCheetahRun(state) {
    const { cheetahRun } = state.dataOperation;
    if (_.isNil(cheetahRun)) {
        return {};
    }

    return cheetahRun;
}

CheetahRunView.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            sourceType: PropTypes.string,
        }),
    }),
};

const condition = (authUser) => !!authUser;

export default withStyles(styles)(withAuthorization(condition)(CheetahRunView));
