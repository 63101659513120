/**
 * Created by 510004 on 1/15/2020.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import FormField from '../formField';
import CheetahRuns from '../cheetahRuns';
import { compose } from 'recompose';

const styles = () => ({
    formContainer: {
        display: 'grid',
        gridGap: '10px',
        height: '100%',
        gridTemplateRows: 'auto 1fr',
    },
    fieldsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 400px))',
        gridAutoRows: '1fr',
        padding: '10px',
        gridGap: '10px',
    },
    runsContainer: {
        display: 'grid',
        gridGap: '10px',
        gridTemplateRows: 'auto 1fr',
    },
    fieldContainer: {
        display: 'grid',
    },
    runsHeaderText: {
        fontSize: 'inherit',
        fontWeight: '600',
    },
});

const fields = [
    { id: 'projectName', label: 'Name' },
    { id: 'projectCreatedDate', label: 'Created', type: 'date' },
    { id: 'lastAccessedDate', label: 'Last Updated', type: 'date' },
];

function CheetahProjectBase(props) {
    const { classes, cheetahProject } = props;

    cheetahProject.runs = cheetahProject.runs || [];

    return (
        <div className={classes.formContainer}>
            <div className={classes.fieldsContainer}>
                {fields.map((field) => {
                    return (
                        <FormField
                            key={field.id}
                            label={field.label}
                            value={cheetahProject[field.id]}
                            type={field.type}
                        />
                    );
                })}
            </div>
            <div className={classes.runsContainer}>
                <div className={classes.runsHeaderText}>Runs</div>
                <CheetahRuns runsList={cheetahProject.runs} />
            </div>
        </div>
    );
}

CheetahProjectBase.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    cheetahProject: PropTypes.object,
    timestamp: PropTypes.number,
    onRefreshClick: PropTypes.func,
};

const CheetahProject = compose(withStyles(styles))(CheetahProjectBase);

export default CheetahProject;
