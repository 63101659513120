/**
 * Created by Eric on 2019-01-28.
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { Typography } from '@material-ui/core';
import 'typeface-roboto';

import App from './app';
import { store } from './_helpers';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './components/firebase';

import { JssProvider } from 'react-jss';
import { create } from 'jss';
import { createGenerateClassName, jssPreset, ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());

Sentry.init({ dsn: 'https://8ca1decb79b64077a17a935cdfc69816@sentry.io/1551537' });

const theme = createTheme({
    typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontSize: 12,
    },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <FirebaseContext.Provider value={new Firebase()}>
            <JssProvider jss={jss} generateClassName={generateClassName}>
                <ThemeProvider theme={theme}>
                    <Typography component={'span'}>
                        <App />
                    </Typography>
                </ThemeProvider>
            </JssProvider>
        </FirebaseContext.Provider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
