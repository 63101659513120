/**
 * Created by 510004 on 1/16/2020.
 */

export const listUtilsService = {
    desc: sortObjects,
    stableSort,
    getFilteredList,
    getPageSizeOptions,
    getSorting,
};

/**
 * Sort function.
 * @param a
 * @param b
 * @param orderBy {string} Object key (field) to sort by.
 * @param orderType {string} Data type hint to help with sorting (ie: sort by 'date' or 'string', etc.).
 * @returns {number} Sort result.
 */
function sortObjects(a, b, orderBy, orderType) {
    let sortA;
    let sortB;

    if (orderType === 'date') {
        sortA = new Date(a[orderBy]).getTime();
        sortB = new Date(b[orderBy]).getTime();
    } else {
        sortA = a[orderBy];
        sortB = b[orderBy];
    }

    if (sortB < sortA) {
        return -1;
    }

    if (sortB > sortA) {
        return 1;
    }

    return 0;
}

function stableSort({ array, cmp }) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) {
            return order;
        }

        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}

/**
 * Object array sort function exposed to other components.
 * @param order {string} Direction, 'asc' or 'desc'.
 * @param orderBy {string} Object property to sort by.
 * @param orderType {string} Property's data type as hint for sorting. Used mainly to sort dates.
 * @returns {{(*, *): number, (*, *): number}}
 */
function getSorting({ order, orderBy, orderType }) {
    return order === 'desc'
        ? (a, b) => sortObjects(a, b, orderBy, orderType)
        : (a, b) => -sortObjects(a, b, orderBy, orderType);
}

function getFilteredList({ list, propName, searchValue }) {
    let filteredList;
    if (!searchValue || searchValue.length === 0) {
        return list;
    }

    const searchLC = searchValue.toLowerCase();
    filteredList = list.filter((project) => project[propName].toLowerCase().indexOf(searchLC) >= 0);
    return filteredList;
}

function getPageSizeOptions(listLength) {
    const pageSizeOptions = [5, 10, 25, 50];
    if (listLength > 50 && listLength <= 200) {
        pageSizeOptions.push(200);
    }

    if (listLength > 200) {
        pageSizeOptions.push(listLength < 400 ? listLength : 400);
    }

    pageSizeOptions.push({ label: 'All', value: -1 });

    return pageSizeOptions;
}
