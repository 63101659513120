/**
 * Created by 300126 on 1/29/2019.
 */

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { history } from './_helpers';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import LandingPage from './components/landing';
import CheetahPage from './components/cheetah';
import CheetahWeb from './components/cheetahWeb';
import CheetahPlayback from './components/cheetahPlayback';
import { NavigationAuth, NavigationNonAuth } from './components/navigation';
import PasswordForgetPage from './components/passwordForget';
import SignInPage from './components/signin';
import SignUpPage from './components/signup';
import VerifyPage from './components/verify';
import Sidebar from './components/sidebar';
import Footer from './components/footer';
import CheetahLogsMain from './pages/cheetahLogsMain';
import CheetahSystemInfoView from './pages/cheetahSystemInfoView';
import CheetahProjectsMain from './pages/cheetahProjectsMain';
import CheetahProjectView from './pages/cheetahProjectView';
import CheetahRunView from './pages/cheetahRunView';
import PlaybackViewer from './pages/playbackViewer';
import MapView from './pages/mapView';
import * as ROUTES from './_constants/routeConstants';
import { appConstants } from './_constants';
import { AuthUserContext, withAuthentication } from './components/session';
import { CssBaseline, withStyles } from '@material-ui/core';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import _ from 'lodash';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
    ManageClientCurvesComponent,
    ManageUserTagsComponent,
    ManageUserRolesComponent,
    ManageExternalUsersComponent,
} from './components/admin';
const generateClassName = createGenerateClassName({
    productionPrefix: 'wd',
});

const styles = () => ({
    itemA: {
        position: 'fixed',
        width: '100%',
        zIndex: '1',
    },
    itemB: {
        gridArea: 'sidebar',
    },
    itemC: {
        gridArea: 'main',
        display: 'grid',
    },
    itemD: {
        gridArea: 'footer',
    },
    container: {
        display: 'grid',
        fontSize: 'inherit',
        height: '100vh',
        paddingTop: '40px',
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: '1fr auto',
        gridTemplateAreas: `
            "sidebar main"
            "footer footer"
            `,
    },
});

function App(props) {
    const [sidebarState, setSidebarState] = useState('DEFAULT');
    const [claims, setClaims] = useState({});
    const [isCustomClaimRequested, setIsCustomClaimRequested] = useState(false);
    //console.log('canViewCheetahWeb ' + canViewCheetahWeb);
    useEffect(() => {
        document.title = appConstants.TITLE;
    }, []);

    useEffect(() => {
        const app = getApp();
        const auth = getAuth(app);
        auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                if (!isCustomClaimRequested) {
                    setIsCustomClaimRequested(true);
                    currentUser
                        .getIdTokenResult()
                        .then((idTokenResult) => {
                            setClaims(idTokenResult.claims);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        });
    }, [isCustomClaimRequested]);

    const { classes } = props;
    const handleDrawerOpen = () => {
        console.log('handleDrawerOpen ');
        setSidebarState('SIDEBAROPEN');
    };
    const handleDrawerClose = () => {
        console.log('handleDrawerClose ');
        setSidebarState('SIDEBARCLOSE');
    };

    const CheetahWebRouteItem = (viewClaim) => {
        if (viewClaim) {
            return <CheetahWeb />;
        }
        return null;
    };

    const ProtectedRouteItem = (props) => {
        const component = props.component;
        const hasClaim = props.hasClaim;
        if (hasClaim) {
            return component;
        }
        return null;
    };
    ProtectedRouteItem.propTypes = {
        component: PropTypes.element, // Validate that 'component' is a React element
        hasClaim: PropTypes.bool, // Validate that 'hasClaim' is a required boolean
    };
    return (
        <BrowserRouter basename={'/'} history={history}>
            <StylesProvider generateClassName={generateClassName}>
                <CssBaseline />
                <div className={classes.itemA}>
                    <AuthUserContext.Consumer>
                        {(authUser) =>
                            !_.isNil(authUser) ? (
                                <NavigationAuth
                                    authUser={authUser}
                                    sidebarMenuState={sidebarState}
                                    handleMenuClick={handleDrawerOpen}
                                />
                            ) : (
                                <NavigationNonAuth />
                            )
                        }
                    </AuthUserContext.Consumer>
                </div>

                <div className={classes.container}>
                    <AuthUserContext.Consumer>
                        {(authUser) =>
                            !_.isNil(authUser) ? (
                                <div className={classes.itemB}>
                                    <Sidebar
                                        sidebarMenuState={sidebarState}
                                        handleMenuClick={handleDrawerClose}
                                        claims={claims}
                                    />
                                </div>
                            ) : (
                                <div />
                            )
                        }
                    </AuthUserContext.Consumer>

                    <div className={classes.itemC}>
                        <Routes>
                            <Route path={ROUTES.LANDING} element={<LandingPage />} />
                            <Route path={ROUTES.PASSWORD_FORGET} element={<PasswordForgetPage />} />
                            <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
                            <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
                            <Route
                                path={ROUTES.LOGS}
                                element={
                                    <ProtectedRouteItem
                                        component={<CheetahLogsMain />}
                                        hasClaim={!claims.isClientView}
                                    />
                                }
                            />
                            <Route
                                path={ROUTES.CHEETAH_PROJECTS}
                                element={
                                    <ProtectedRouteItem
                                        component={<CheetahProjectsMain />}
                                        hasClaim={!claims.isClientView}
                                    />
                                }
                            />
                            <Route
                                path={`${ROUTES.CHEETAH_PROJECT}:id/:sourceType`}
                                element={
                                    <ProtectedRouteItem
                                        component={<CheetahProjectView />}
                                        hasClaim={!claims.isClientView}
                                    />
                                }
                            />
                            <Route
                                path={`${ROUTES.CHEETAH_RUN}:id/:sourceType`}
                                element={
                                    <ProtectedRouteItem
                                        component={<CheetahRunView />}
                                        hasClaim={!claims.isClientView}
                                    />
                                }
                            />
                            <Route
                                path={ROUTES.CHEETAH_SYSTEM_INFO}
                                element={
                                    <ProtectedRouteItem
                                        component={<CheetahSystemInfoView />}
                                        hasClaim={!claims.isClientView}
                                    />
                                }
                            />
                            <Route path={ROUTES.MAPS} element={<MapView />} />
                            <Route
                                path={`${ROUTES.PLAYBACK}/:id`}
                                element={
                                    <ProtectedRouteItem
                                        component={<PlaybackViewer />}
                                        hasClaim={!claims.isClientView}
                                    />
                                }
                            />
                            <Route path={ROUTES.VERIFY} element={<VerifyPage />} />
                            <Route path={`${ROUTES.CHEETAH}`} element={<CheetahPage />} />
                            <Route path={`${ROUTES.CHEETAH}:version`} element={<CheetahPage />} />
                            <Route
                                path={`${ROUTES.CHEETAH_PLAYBACK}`}
                                element={
                                    <ProtectedRouteItem
                                        component={<CheetahPlayback />}
                                        hasClaim={!claims.isClientView}
                                    />
                                }
                            />
                            <Route
                                path={`${ROUTES.CHEETAH_WEB}`}
                                element={<CheetahWebRouteItem viewClaim={claims.canViewCheetahWeb} />}
                            />
                            <Route
                                path={`${ROUTES.ADMIN}${ROUTES.ADMIN_USER_ROLES.replace(/ /g, '').toLowerCase()}`}
                                element={
                                    <ProtectedRouteItem
                                        component={<ManageUserRolesComponent />}
                                        hasClaim={claims.isWellDataAdmin}
                                    />
                                }
                            />
                            <Route
                                path={`${ROUTES.ADMIN}${ROUTES.ADMIN_USER_TAGS.replace(/ /g, '').toLowerCase()}`}
                                element={
                                    <ProtectedRouteItem
                                        component={<ManageUserTagsComponent />}
                                        hasClaim={claims.isWellDataAdmin}
                                    />
                                }
                            />
                            <Route
                                path={`${ROUTES.ADMIN}${ROUTES.ADMIN_CLIENT_CURVES.replace(/ /g, '').toLowerCase()}`}
                                element={
                                    <ProtectedRouteItem
                                        component={<ManageClientCurvesComponent />}
                                        hasClaim={claims.isWellDataAdmin}
                                    />
                                }
                            />
                            <Route
                                path={`${ROUTES.ADMIN}${ROUTES.ADMIN_CLIENT_USERS.replace(/ /g, '').toLowerCase()}`}
                                element={
                                    <ProtectedRouteItem
                                        component={<ManageExternalUsersComponent />}
                                        hasClaim={claims.isWellDataAdmin}
                                    />
                                }
                            />
                        </Routes>
                    </div>

                    <div className={classes.itemD}>
                        <Footer />
                    </div>
                </div>
            </StylesProvider>
        </BrowserRouter>
    );
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    firebase: PropTypes.object,
    path: PropTypes.string,
    element: PropTypes.element,
    viewClaim: PropTypes.bool,
};

export default compose(withAuthentication(withStyles(styles)(App)));
