/**
 * Created by 300126 on 1/29/2019.
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withStyles, Fab, Paper, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import Logs from '../components/logs';
import { withAuthorization } from '../components/session';
import _ from 'lodash';

const TITLE = 'Cheetah Log Files';
const COUNT_LABEL = 'Total Log Files: ';
const REFRESH_LABEL = 'Last Refreshed: ';

const styles = (theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        height: 'calc(100vh - 74px)',
        width: '100%',
    },
    header: {
        alignItems: 'baseline',
        display: 'flex',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: '1.25rem',
        fontWeight: '700',
    },
    refreshButton: {
        marginBottom: '5px',
    },
    refreshEcho: {
        fontSize: '0.7rem',
        marginRight: '10px',
    },
    refreshLabel: {
        fontWeight: '500',
    },
});

const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
};

function getResult(state) {
    const { fileOperation } = state;
    if (_.isNil(fileOperation) || _.isNil(fileOperation.result)) {
        return {};
    }
    return fileOperation.result;
}

function CheetahLogsMain(props) {
    const { classes, timestamp = Date.now() } = props;
    const result = useSelector((state) => getResult(state));
    const refreshFn = useRef(null);

    const onRefreshClick = () => {
        refreshFn.current();
    };

    const { totalCount = 0 } = result;

    return (
        <Paper className={classes.paper}>
            <div className={classes.header}>
                <span className={classes.headerText}>{TITLE}</span>
                <span className={classes.refreshEcho}>
                    <span className={classes.refreshLabel}>{COUNT_LABEL}</span>
                    <span>{totalCount} </span>
                </span>
                <div>
                    <span className={classes.refreshEcho}>
                        <span className={classes.refreshLabel}>{REFRESH_LABEL}</span>
                        <span>{new Intl.DateTimeFormat('default', options).format(timestamp)} </span>
                    </span>
                    <Tooltip title="Refresh" aria-label="Add">
                        <Fab size="small" className={classes.refreshButton} onClick={() => onRefreshClick()}>
                            <RefreshIcon />
                        </Fab>
                    </Tooltip>
                </div>
            </div>
            <Logs childFn={refreshFn} />
        </Paper>
    );
}

CheetahLogsMain.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    fileList: PropTypes.array,
    timestamp: PropTypes.number,
    onRefreshClick: PropTypes.func,
};

const condition = (authUser) => !!authUser;

export default withStyles(styles)(withAuthorization(condition)(CheetahLogsMain));
