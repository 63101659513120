/**
 * Created by 510004 on 2/20/2019.
 */
import { initializeApp } from 'firebase/app';
import { configService } from '../../_services';
import {
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    fetchSignInMethodsForEmail,
    createUserWithEmailAndPassword,
    sendEmailVerification,
} from 'firebase/auth';

const authConfig = await configService.getAuthConfig();

class Firebase {
    constructor() {
        const app = initializeApp(authConfig);
        this.auth = getAuth(app);
    }

    doSignInWithPopup = (provider) => signInWithPopup(this.auth, provider);
    doSignInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword(this.auth, email, password);

    doSignOut = () => signOut(this.auth);

    doPasswordReset = (email) => sendPasswordResetEmail(this.auth, email);

    doFetchSignInMethods = (email) => fetchSignInMethodsForEmail(this.auth, email);

    doCreateUserWithEmailAndPassword = (email, password) => createUserWithEmailAndPassword(this.auth, email, password);

    doSendEmailVerification = () => sendEmailVerification(this.auth.currentUser);

    getCurrentUser = () => {
        return this.auth.currentUser;
    };
}

export default Firebase;
