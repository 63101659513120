/**
 * Created by 510004 on 2/23/2019.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, withStyles } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DownloadButton from '../downloadButton/index';
import { fileOperationActions } from '../../_actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { CustomPagination, EnhancedTableHead, TableCellLink, TableSearchField } from '../table';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { formatService, listUtilsService } from '../../_services';
import _ from 'lodash';
import { queryConstants } from '../../_constants';
import { GridLoader } from 'react-spinners';

const defaultPadding = '0 15px 0 15px';

const downloadStyle = {
    padding: defaultPadding,
    textAlign: 'left',
    width: '1%',
};

const styles = () => ({
    container: {},
    root: {
        width: '100%',
    },
    table: {
        minWidth: 700,
    },
    tableBody: {
        display: 'block',
        overflowX: 'visible',
        overflowY: 'scroll',
    },
    row: {
        height: 'auto',
    },
    searchContainer: {
        justifySelf: 'right',
    },
    tableContainer: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        height: '100%',
    },
    loaderContainer: {
        alignSelf: 'center',
        display: 'grid',
        justifySelf: 'center',
    },
    loaderParentContainer: {
        display: 'grid',
        height: '100%',
    },
});

const columns = [
    {
        id: 'options',
        numeric: false,
        disablePadding: false,
        label: 'Download',
        type: 'downloadButton',
        minWidth: '1%',
        disableSort: true,
        cellStyle: downloadStyle,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        align: 'left',
        label: 'File Name',
        type: 'string',
        minWidth: '45%',
    },
    {
        id: 'projectName',
        numeric: false,
        disablePadding: false,
        align: 'left',
        label: 'Project Name',
        type: 'string',
        minWidth: '25%',
    },
    {
        id: 'size',
        numeric: true,
        disablePadding: false,
        label: 'Size',
        align: 'right',
        type: 'number',
        minWidth: '9%',
        format: (value) => formatService.formatBytes(value),
    },
    {
        id: 'timeCreated',
        numeric: true,
        disablePadding: false,
        label: 'Uploaded Date',
        type: 'date',
        minWidth: '20%',
        format: (value) => formatService.formatDate(value),
    },
];

const theme = createTheme({
    overrides: {
        MuiButton: {
            text: {
                padding: '0',
            },
        },
        MuiCircularProgress: {
            root: {
                position: 'absolute',
            },
        },
        MuiTable: {
            stickyHeader: {
                borderCollapse: 'collapse',
            },
        },
        MuiTablePagination: {
            toolbar: {
                minHeight: 'auto',
            },
        },
        MuiPaper: {
            elevation1: {
                height: 'calc(100% - 45px)',
            },
            elevation2: {
                height: 'calc(100% - 45px)',
            },
        },
        MuiTableCell: {
            root: {
                fontSize: 'inherit',
            },
        },
    },
});

function selectResult(state) {
    return state.fileOperation.result;
}

function selectFetchingFileList(state) {
    return state.fileOperation.fetchingFileList;
}

const LOADER_SIZE_PX = 20;
const LOADER_COLOR = '#105F9A';
const SORT_DESC = 'desc';
const SORT_ASC = 'asc';

function fetchCheetahLogs({
    dispatch,
    first,
    last,
    nextCursor,
    previousCursor,
    order,
    orderBy,
    orderType,
    searchValue,
    searchField,
}) {
    dispatch(
        fileOperationActions.getFileList({
            first: first,
            afterCursor: nextCursor,
            beforeCursor: previousCursor,
            last: last,
            order: order,
            orderBy: orderBy,
            orderType: orderType,
            searchValue: searchValue,
            searchField: searchField,
        })
    );
}

function CheetahLogsBase(props) {
    const { childFn } = props;

    // State.
    const [order, setOrder] = useState(SORT_DESC);
    const [orderBy, setOrderBy] = useState('timeCreated');
    const [orderType, setOrderType] = useState('date');
    const [page, setPage] = useState(0); // NOTE: 0-based page index for TablePagination support.
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchValue, setSearchValue] = useState(null);
    const [nextCursor, setNextCursor] = useState(null);
    const [previousCursor, setPreviousCursor] = useState(null);
    const [requestedName, setRequestedName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [listLoading, setListLoading] = useState(false);

    // Variables.
    let currentNextCursor = nextCursor;
    let currentPreviousCursor = previousCursor;
    let first = rowsPerPage;
    let last = null;
    let currentRowsPerPage = rowsPerPage;
    let currentOrder = order;
    let currentOrderBy = orderBy;
    let currentOrderType = orderType;
    let currentSearchValue = searchValue;

    const result = useSelector((state) => selectResult(state));
    const fetchingFileList = useSelector((state) => selectFetchingFileList(state));

    const { edges: fileList = [], totalCount = 0, pageInfo = {} } = result;
    const totalPages = totalCount / rowsPerPage;
    const { startCursor } = pageInfo;
    const dispatch = useDispatch();

    if (fetchingFileList !== listLoading) {
        setListLoading(fetchingFileList);
    }

    useEffect(() => {
        refreshData();
        childFn.current = refreshData;
    }, []);

    const updateSearch = (value) => {
        setSearchValue(value);
        currentSearchValue = value;
        refreshData();
    };

    const handleSearchDebounced = _.debounce(updateSearch, 1000);

    const handleRequestSort = (event, property) => {
        setOrderBy(property);
        currentOrderBy = property;

        const col = columns.find((column) => column.id === currentOrderBy);
        if (!_.isNil(col) && !_.isNil(col.type)) {
            setOrderType(col.type);
            currentOrderType = col.type;
        }

        setOrder(SORT_DESC);
        currentOrder = SORT_DESC;

        if (orderBy === property && order === SORT_DESC) {
            setOrder(SORT_ASC);
            currentOrder = SORT_ASC;
        }

        refreshData();
    };

    const refreshData = () => {
        fetchCheetahLogs({
            dispatch: dispatch,
            first: first,
            nextCursor: currentNextCursor,
            previousCursor: currentPreviousCursor,
            last: last,
            orderType: currentOrderType,
            orderBy: currentOrderBy,
            order: currentOrder,
            searchValue: currentSearchValue,
            searchField: [queryConstants.CHEETAHPROJECT.PROJECT_NAME, 'name'],
        });
    };

    const handleChangePage = (event, newPage) => {
        // NOTE: newPage is 0-based, so it behaves like a page index. - reg.

        const lastPage = newPage + 1 >= totalPages;
        const firstPage = newPage === 0;

        const direction = newPage > page ? 'next' : 'previous';

        currentNextCursor = !lastPage && direction === 'next' ? startCursor : null;
        setNextCursor(currentNextCursor);
        currentPreviousCursor = !firstPage && direction === 'previous' ? startCursor : null;
        setPreviousCursor(previousCursor);

        setPage(newPage);
        first = null;
        last = null;

        if (firstPage) {
            first = rowsPerPage;
            refreshData();
            return;
        }

        if (lastPage) {
            last = rowsPerPage;
            refreshData();
            return;
        }

        if (direction === 'next') {
            first = rowsPerPage;
            refreshData();
            return;
        }

        if (direction === 'previous') {
            last = rowsPerPage;
            refreshData();
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);

        if (newRowsPerPage === rowsPerPage) {
            return;
        }

        currentRowsPerPage = newRowsPerPage;
        setRowsPerPage(newRowsPerPage);

        if (first === rowsPerPage) {
            first = currentRowsPerPage;
            refreshData();
            return;
        }

        if (last === rowsPerPage) {
            last = currentRowsPerPage;
            refreshData();
        }
    };

    const handleSearch = (event) => {
        handleSearchDebounced(event.target.value);
    };

    const handleDownload = ({ authUser, fileName }) => {
        setLoading(true);
        setRequestedName(fileName);
        dispatch(fileOperationActions.getFile({ authUser: authUser, fileName: fileName }));
    };

    const { classes, fetchingFile } = props;
    const finalFilesList = [];
    for (let i = 0, len = fileList.length; i < len; i++) {
        const item = fileList[i];
        finalFilesList.push(item.node);
    }

    const pageSizeOptions = listUtilsService.getPageSizeOptions(totalCount);
    const emptyRows = Math.min(rowsPerPage, finalFilesList.length - rowsPerPage);

    if (finalFilesList.length > 50 && finalFilesList.length <= 200) {
        pageSizeOptions.push(200);
    }

    if (finalFilesList.length > 200) {
        pageSizeOptions.push(finalFilesList.length < 400 ? finalFilesList.length : 400);
    }

    return (
        <ThemeProvider theme={theme}>
            <Paper className={classes.root}>
                {listLoading ? (
                    <div className={classes.loaderParentContainer}>
                        <div className={classes.loaderContainer}>
                            <GridLoader size={LOADER_SIZE_PX} color={LOADER_COLOR} loading={listLoading} />
                        </div>
                    </div>
                ) : (
                    <div className={classes.tableContainer}>
                        <div className={classes.searchContainer}>
                            <TableSearchField searchChange={handleSearch} searchClick={handleSearch} />
                        </div>
                        <TableContainer className={classes.container}>
                            <Table
                                className={classes.table}
                                stickyHeader
                                aria-label="a dense sticky table"
                                size="small">
                                <EnhancedTableHead
                                    columns={columns}
                                    onRequestSort={handleRequestSort}
                                    order={order}
                                    orderBy={orderBy}
                                    rowCount={finalFilesList.length}
                                />
                                <TableBody>
                                    {finalFilesList.map((row, i) => {
                                        const { id: rowId } = row;

                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                                {columns.map((column) => {
                                                    const { id, type, align, minWidth } = column;
                                                    const value = row[id];

                                                    return column.type && column.type === 'link' ? (
                                                        <TableCellLink
                                                            key={`linkcell-${id}-${rowId}`}
                                                            column={column}
                                                            row={row}
                                                            value={value}
                                                        />
                                                    ) : type && type === 'downloadButton' ? (
                                                        <TableCell key={id} align={align} style={{ width: minWidth }}>
                                                            <DownloadButton
                                                                rowData={row}
                                                                loading={loading}
                                                                requestedName={requestedName}
                                                                fetchingFile={fetchingFile}
                                                                handleDownload={handleDownload}
                                                            />
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell key={id} align={align} style={{ width: minWidth }}>
                                                            {column.format ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <CustomPagination
                            colSpan={columns.length}
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            pageSizeOptions={pageSizeOptions}
                            onRequestChangePage={handleChangePage}
                            onRequestChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                )}
            </Paper>
        </ThemeProvider>
    );
}

function mapStateToProps(state) {
    const { file, fetchingFile } = state.fileOperation;
    return {
        file: file,
        fetchingFile: fetchingFile,
    };
}

CheetahLogsBase.propTypes = {
    classes: PropTypes.object.isRequired,
    childFn: PropTypes.object,
    fetchingFile: PropTypes.bool,
};

const Logs = compose(connect(mapStateToProps), withStyles(styles))(CheetahLogsBase);

export default Logs;
