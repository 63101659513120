/**
 * Created by 510004 on 1/15/2020.
 */

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { dataActions, navActions } from '../_actions';
import { withAuthorization } from '../components/session';
import CheetahProject from '../components/cheetahProject';
import CustomBreadcrumbs from '../components/breadcrumbs';
import * as ROUTES from '../_constants/routeConstants';
import _ from 'lodash';

const styles = (theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        height: '100%',
        width: '100%',
    },
    fieldsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        padding: '10px',
        gridGap: '10px',
    },
    fieldContainer: {
        display: 'grid',
    },
    formContainer: {
        display: 'grid',
        height: '100%',
        gridTemplateRows: 'auto auto 1fr',
        padding: '20px',
    },
    header: {
        alignItems: 'baseline',
        display: 'grid',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: '1.25rem',
        fontWeight: '700',
    },
    runsHeaderText: {
        fontSize: 'inherit',
        fontWeight: '600',
    },
});

function CheetahProjectView(props) {
    const params = useParams();
    let currentId = params.id;
    let source = params.sourceType;
    const { classes } = props;

    const dispatch = useDispatch();
    const cheetahProject = useSelector((state) => getCheetahProject(state));

    function fetchCheetahProject() {
        dispatch(dataActions.getRecord({ type: 'cheetahProject', recordId: currentId, sourceType: source }));
    }
    // function fetchCheetahProject() {
    //     dispatch(dataActions.getRecord({ type: 'cheetahProject', recordId: currentId}));
    // }

    useEffect(() => {
        if (_.isNil(currentId)) {
            return;
        }

        fetchCheetahProject();

        return function cleanup() {
            dispatch(dataActions.resetRecord({ recordType: 'cheetahProject' }));
        };
    }, [currentId]);

    const resolvedProject = { ...cheetahProject } || {};
    resolvedProject.runs = resolvedProject.runs ?? [];
    const { projectName } = resolvedProject;

    useEffect(() => {
        if (!_.isNil(projectName)) {
            const navBase = { title: 'Cheetah Projects', url: `../${ROUTES.CHEETAH_PROJECTS}` };
            const target = { title: projectName, url: `../${ROUTES.CHEETAH_PROJECT}${currentId}` };
            const orderedLinks = [navBase];

            dispatch(navActions.setBreadcrumbs(orderedLinks));
            dispatch(navActions.setFrom(navBase));
            dispatch(navActions.setTo(target));
        }
    }, [projectName]);

    return (
        <div className={classes.formContainer}>
            <CustomBreadcrumbs />
            <div className={classes.header}>
                <span className={classes.headerText}>{projectName}</span>
            </div>
            <CheetahProject cheetahProject={resolvedProject} />
        </div>
    );
}

function getCheetahProject(state) {
    const { cheetahProject } = state.dataOperation;
    if (_.isNil(cheetahProject)) {
        return {};
    }

    return cheetahProject;
}

CheetahProjectView.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            sourceType: PropTypes.string,
        }),
    }),
};

const condition = (authUser) => !!authUser;

export default withStyles(styles)(withAuthorization(condition)(CheetahProjectView));
