/**
 * Created by 510004 on 1/14/2020.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { ThemeProvider } from '@material-ui/styles';
import { CustomTableCell, CustomPagination, EnhancedTableHead, TableCellLink, TableSearchField } from '../table/index';
import { Paper } from '@material-ui/core';

import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { compose } from 'recompose';
import * as ROUTES from '../../_constants/routeConstants';
import { formatService, listUtilsService } from '../../_services';
import { createTheme } from '@material-ui/core/styles';
import { dataActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { queryConstants } from '../../_constants';
import { GridLoader } from 'react-spinners';

const styles = () => ({
    container: {},
    root: {
        width: '100%',
        // display: 'grid',
    },
    table: {
        minWidth: 700,
    },
    tableBody: {
        display: 'block',
        overflowX: 'visible',
        overflowY: 'scroll',
    },
    row: {
        height: 'auto',
    },
    buttonContainer: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        position: 'relative',
    },
    searchContainer: {
        justifySelf: 'right',
    },
    tableContainer: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        height: '100%',
    },
    loaderContainer: {
        alignSelf: 'center',
        display: 'grid',
        justifySelf: 'center',
    },
    loaderParentContainer: {
        display: 'grid',
        height: '100%',
    },
    disabledCell: {
        color: 'lightgray',
    },
});

const columns = [
    {
        id: queryConstants.CHEETAHPROJECT.PROJECT_NAME,
        label: 'Name',
        minWidth: '30%',
        type: 'link',
        route: `../${ROUTES.CHEETAH_PROJECT}`,
        paramFields: {
            id: 'id',
            sourceType: 'sourceType',
        },
    },
    {
        id: queryConstants.CHEETAHPROJECT.HAS_PLAYBACK,
        icon: 'Insights',
        label: 'Playback',
        align: 'center',
        type: 'link',
        route: `../${ROUTES.CHEETAH_PLAYBACK}?`,
        queryParams: {
            projectId: 'id',
        },
    },
    {
        id: queryConstants.CHEETAHPROJECT.LAST_ACCESSED_DATE,
        label: 'Last Accessed Date',
        align: 'center',
        type: 'date',
        format: (value) => formatService.formatDate(value),
    },
    {
        id: queryConstants.CHEETAHPROJECT.PROJECT_CREATED_DATE,
        label: 'Created',
        align: 'center',
        type: 'date',
        format: (value) => formatService.formatDate(value),
    },
    {
        id: queryConstants.CHEETAHPROJECT.RUN_COUNT,
        label: 'Runs',
        align: 'center',
        type: 'number',
    },
    {
        id: queryConstants.CHEETAHPROJECT.SOFTWARE_CHEETAH_VERSION,
        label: 'Latest Cheetah',
        align: 'center',
        type: 'semver',
    },
    {
        id: queryConstants.CHEETAHPROJECT.HAS_TOOLS,
        label: 'Tools Recorded',
        align: 'center',
        type: 'bool',
    },
];

const theme = createTheme({
    overrides: {
        MuiIconButton: {
            root: {
                padding: '6px 12px',
            },
        },
        MuiTablePagination: {
            toolbar: {
                minHeight: 'auto',
            },
        },
        MuiPaper: {
            elevation1: {
                height: 'calc(100% - 45px)',
            },
            elevation2: {
                height: 'calc(100% - 45px)',
            },
        },
        MuiTableCell: {
            root: {
                fontSize: 'inherit',
            },
        },
    },
});

const LOADER_SIZE_PX = 20;
const LOADER_COLOR = '#105F9A';
const SORT_DESC = 'desc';
const SORT_ASC = 'asc';

function selectResult(state) {
    const { dataOperation } = state;
    return dataOperation.result;
}

function selectFetchingList(state) {
    const { dataOperation } = state;
    return dataOperation.fetchingList;
}

function CheetahProjectsList(props) {
    const { childFn } = props;

    function fetchProjects({
        refresh = false,
        dispatch,
        first,
        last,
        nextCursor,
        previousCursor,
        order,
        orderBy,
        orderType,
        searchValue,
        searchField,
    }) {
        dispatch(
            dataActions.getProjectsList({
                refresh: refresh,
                first: first,
                afterCursor: nextCursor,
                beforeCursor: previousCursor,
                last: last,
                order: order,
                orderBy: orderBy,
                orderType: orderType,
                searchValue: searchValue,
                searchField: searchField,
            })
        );
        setLoading(false);
    }

    // State.
    const [order, setOrder] = useState(SORT_DESC);
    const [orderBy, setOrderBy] = useState(queryConstants.CHEETAHPROJECT.LAST_ACCESSED_DATE);
    const [orderType, setOrderType] = useState('date');
    const [page, setPage] = useState(0); // NOTE: 0-based page index for TablePagination support.
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchValue, setSearchValue] = useState(null);
    const [nextCursor, setNextCursor] = useState(null);
    const [previousCursor, setPreviousCursor] = useState(null);
    const [loading, setLoading] = useState(false);

    // Variables.
    let currentNextCursor = nextCursor;
    let currentPreviousCursor = previousCursor;
    let first = rowsPerPage;
    let last = null;
    let currentRowsPerPage = rowsPerPage;
    let currentOrder = order;
    let currentOrderBy = orderBy;
    let currentOrderType = orderType;
    let currentSearchValue = searchValue;

    const result = useSelector((state) => selectResult(state));
    const fetchingList = useSelector((state) => selectFetchingList(state));

    const { edges: projectsList = [], totalCount = 0, pageInfo = {} } = result;
    const totalPages = totalCount / rowsPerPage;
    const { startCursor } = pageInfo;
    const dispatch = useDispatch();

    if (fetchingList !== loading) {
        setLoading(fetchingList);
    }

    useEffect(() => {
        refreshData();
        childFn.current = refreshData;
    }, []);

    const updateSearch = (value) => {
        setSearchValue(value);
        currentSearchValue = value;
        refreshData();
    };

    const handleSearchDebounced = _.debounce(updateSearch, 1000);

    const handleRequestSort = (event, property) => {
        setOrderBy(property);
        currentOrderBy = property;

        const col = columns.find((column) => column.id === currentOrderBy);
        if (!_.isNil(col) && !_.isNil(col.type)) {
            setOrderType(col.type);
            currentOrderType = col.type;
        }

        setOrder(SORT_DESC);
        currentOrder = SORT_DESC;

        if (orderBy === property && order === SORT_DESC) {
            setOrder(SORT_ASC);
            currentOrder = SORT_ASC;
        }

        refreshData();
    };

    const refreshData = (refresh = false) => {
        fetchProjects({
            refresh: refresh,
            dispatch: dispatch,
            first: first,
            nextCursor: currentNextCursor,
            previousCursor: currentPreviousCursor,
            last: last,
            orderType: currentOrderType,
            orderBy: currentOrderBy,
            order: currentOrder,
            searchValue: currentSearchValue,
            searchField: queryConstants.CHEETAHPROJECT.PROJECT_NAME,
        });
    };

    const handleChangePage = (event, newPage) => {
        // NOTE: newPage is 0-based, so it behaves like a page index. - reg.

        const lastPage = newPage + 1 >= totalPages;
        const firstPage = newPage === 0;

        const direction = newPage > page ? 'next' : 'previous';

        currentNextCursor = !lastPage && direction === 'next' ? startCursor : null;
        setNextCursor(currentNextCursor);
        currentPreviousCursor = !firstPage && direction === 'previous' ? startCursor : null;
        setPreviousCursor(previousCursor);

        setPage(newPage);
        first = null;
        last = null;

        if (firstPage) {
            first = rowsPerPage;
            refreshData();
            return;
        }

        if (lastPage) {
            last = rowsPerPage;
            refreshData();
            return;
        }

        if (direction === 'next') {
            first = rowsPerPage;
            refreshData();
            return;
        }

        if (direction === 'previous') {
            last = rowsPerPage;
            refreshData();
        }
    };

    const handleChangeRowsPerPage = (event) => {
        // setPage(0);
        const newRowsPerPage = parseInt(event.target.value, 10);

        if (newRowsPerPage === rowsPerPage) {
            return;
        }

        currentRowsPerPage = newRowsPerPage;
        setRowsPerPage(newRowsPerPage);

        if (first === rowsPerPage) {
            first = currentRowsPerPage;
            refreshData();
            return;
        }

        if (last === rowsPerPage) {
            last = currentRowsPerPage;
            refreshData();
        }
    };

    const handleSearch = (event) => {
        handleSearchDebounced(event.target.value);
    };

    const { classes } = props;
    const finalProjectsList = [];
    for (let i = 0, len = projectsList.length; i < len; i++) {
        const item = projectsList[i];
        finalProjectsList.push(item.node);
    }

    const pageSizeOptions = listUtilsService.getPageSizeOptions(totalCount);
    const emptyRows = Math.min(rowsPerPage, finalProjectsList.length - rowsPerPage);

    return (
        <ThemeProvider theme={theme}>
            <Paper className={classes.root}>
                {loading ? (
                    <div className={classes.loaderParentContainer}>
                        <div className={classes.loaderContainer}>
                            <GridLoader size={LOADER_SIZE_PX} color={LOADER_COLOR} loading={loading} />
                        </div>
                    </div>
                ) : (
                    <div className={classes.tableContainer}>
                        <div className={classes.searchContainer}>
                            <TableSearchField searchChange={handleSearch} searchClick={handleSearch} />
                        </div>
                        <TableContainer className={classes.container}>
                            <Table
                                className={classes.table}
                                stickyHeader
                                aria-label="a dense sticky table"
                                size="small">
                                <EnhancedTableHead
                                    columns={columns}
                                    onRequestSort={handleRequestSort}
                                    order={order}
                                    orderBy={orderBy}
                                    rowCount={finalProjectsList.length}
                                />
                                <TableBody>
                                    {finalProjectsList.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row['id']}>
                                                {columns.map((column) => {
                                                    const { id, type } = column;
                                                    const value = row[id];
                                                    const showLink =
                                                        type &&
                                                        type === 'link' &&
                                                        id === queryConstants.CHEETAHPROJECT.PROJECT_NAME
                                                            ? true
                                                            : !!(
                                                                  type &&
                                                                  type === 'link' &&
                                                                  id === queryConstants.CHEETAHPROJECT.HAS_PLAYBACK &&
                                                                  row[queryConstants.CHEETAHPROJECT.HAS_PLAYBACK]
                                                              );
                                                    return type && type === 'link' ? (
                                                        showLink === true ? (
                                                            <TableCellLink
                                                                key={`linkcell-${id}-${row['id']}`}
                                                                column={column}
                                                                row={row}
                                                                value={value}
                                                            />
                                                        ) : (
                                                            <TableCell
                                                                align={column.align}
                                                                key={`cell-${id}-${row['id']}`}
                                                                className={classes.disabledCell}
                                                                style={{ width: column.minWidth }}
                                                                value={false}>
                                                                NA
                                                            </TableCell>
                                                        )
                                                    ) : (
                                                        <CustomTableCell
                                                            key={`cell-${id}-${row['id']}`}
                                                            column={column}
                                                            value={value}
                                                        />
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={columns.length} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomPagination
                            colSpan={columns.length}
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            pageSizeOptions={pageSizeOptions}
                            onRequestChangePage={handleChangePage}
                            onRequestChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                )}
            </Paper>
        </ThemeProvider>
    );
}

CheetahProjectsList.propTypes = {
    classes: PropTypes.object.isRequired,
    childFn: PropTypes.object,
};

const CheetahProjects = compose(withStyles(styles))(CheetahProjectsList);

export default CheetahProjects;
