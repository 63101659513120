/**
 * Created by 510004 on 9/3/2019.
 */

export const dataConstants = {
    GET_IMAGE_REQUEST: 'GET_IMAGE_REQUEST',
    GET_IMAGE_SUCCESS: 'GET_IMAGE_SUCCESS',
    GET_IMAGE_FAILURE: 'GET_IMAGE_FAILURE',

    GET_LIST_REQUEST: 'GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'GET_LIST_FAILURE',

    GET_PROJECTLIST_REQUEST: 'GET_PROJECTLIST_REQUEST',
    GET_PROJECTLIST_SUCCESS: 'GET_PROJECTLIST_SUCCESS',
    GET_PROJECTLIST_FAILURE: 'GET_PROJECTLIST_FAILURE',

    GET_RECORD_REQUEST: 'GET_RECORD_REQUEST',
    GET_RECORD_SUCCESS: 'GET_RECORD_SUCCESS',
    GET_RECORD_FAILURE: 'GET_RECORD_FAILURE',

    GET_BLOB_REQUEST: 'GET_BLOB_REQUEST',
    GET_BLOB_SUCCESS: 'GET_BLOB_SUCCESS',
    GET_BLOB_FAILURE: 'GET_BLOB_FAILURE',

    RESET_RECORD: 'RESET_RECORD',
};
