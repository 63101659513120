/**
 * Created by 300126 on 2/7/2019.
 */

import { queryConstants } from '../_constants';
import _ from 'lodash';

export const fetchService = {
    fetchFileList,
    fetchFile,
    fetchImage,
    fetchResourceFile,
    fetchRecord,
    fetchSystemInfoRecord,
    fetchSystemInfoRecordByProjectSession,
    fetchDocuments,
    fetchList,
    fetchDataList,
    fetchPagedCheetahProjects,
    fetchBlob,
};

let abortController = new AbortController();

async function fetchFileList({
    refresh = false,
    first,
    afterCursor,
    last,
    beforeCursor,
    order,
    orderBy,
    orderType,
    searchValue,
    searchField,
}) {
    abortController.abort();
    abortController = new AbortController();

    const pageArgs = [];

    pageArgs.push(`refresh:${refresh}`);

    if (!_.isNil(first) && !_.isNil(last)) {
        throw new Error(`Both 'first' and 'last' provided. Only one or the other should be given.`);
    }

    // 'next page' arguments.
    if (!_.isNil(first) && !_.isNil(afterCursor)) {
        pageArgs.push(`first:${first},afterCursor:"${afterCursor}"`);
    }

    // 'previous page' arguments.
    if (!_.isNil(last) && !_.isNil(beforeCursor)) {
        pageArgs.push(`last:${last},beforeCursor:"${beforeCursor}"`);
    }

    // order (sorting).
    if (!_.isNil(order)) {
        pageArgs.push(`order:"${order}"`);

        if (!_.isNil(orderBy)) {
            pageArgs.push(`orderBy:"${orderBy}"`);
        }

        if (!_.isNil(orderType)) {
            pageArgs.push(`orderType:"${orderType}"`);
        }
    }

    if (!_.isNil(searchValue)) {
        pageArgs.push(`searchValue:"${searchValue}"`);

        if (!_.isNil(searchField)) {
            pageArgs.push(`searchField:"${searchField}"`);
        }
    }

    const queryArgs = `(${pageArgs.join(',')})`;
    const queryName = 'cheetahLogs';
    const graphQlQuery = `{ ${queryName}${queryArgs} {${queryConstants.QUERYCHEETAHLOGSCURSORFIELDS}} }`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: graphQlQuery }),
        signal: abortController.signal,
    };

    try {
        const response = await fetch('/graphql', requestOptions);
        const data = await response.json();
        const { data: innerData } = data;

        if (!_.isNil(innerData)) {
            return innerData; // totalCount, edges (list), pageInfo. - reg.
        }

        const { errors } = data;
        if (!_.isNil(errors)) {
            console.error(JSON.stringify(errors));
            return { hasErrors: true, errors: errors };
        }

        return { isEmpty: true };
    } catch (err) {
        if (err.name === 'AbortError') {
            return []; // Continuation logic has already been skipped, so return normally
        }

        return err.message;
    }
}

async function fetchFile({ authUser, fileName }) {
    if (_.isNil(authUser) || _.isNil(fileName)) {
        return null;
    }

    abortController.abort();
    abortController = new AbortController();

    const token = await authUser.getIdToken(true);

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
        signal: abortController.signal,
    };

    const res = await fetch(`/api/getFile/${fileName}`, requestOptions);

    if (!res.ok) {
        throw new Error('Response was not okay');
    }

    return await res.blob();
}

async function fetchResourceFile({ version, fileName }) {
    if (_.isNil(version) || _.isNil(fileName)) {
        return null;
    }

    abortController.abort();
    abortController = new AbortController();

    try {
        const res = await fetch(`/api/getResourceFile/${version}/${fileName}`, { signal: abortController.signal });
        return await res.text();
    } catch (err) {
        if (err.name === 'AbortError') {
            return; // Continuation logic has already been skipped, so return normally
        }

        return err.message;
    }
}

async function fetchList({ listName }) {
    if (_.isNil(listName)) {
        return [];
    }

    abortController.abort();
    abortController = new AbortController();

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: `{ ${listName} }` }),
        // signal: abortController.signal,
    };

    try {
        const response = await fetch('/graphql', requestOptions);
        const data = await response.json();
        if (data.data && data.data.versions) {
            return data.data.versions.slice(0);
        }

        const { errors } = data;
        if (!_.isNil(errors)) {
            console.error(JSON.stringify(errors));
            return ['errors were detected'];
        }

        return ['empty'];
    } catch (err) {
        if (err.name === 'AbortError') {
            console.error(`getList [${listName}] ${JSON.stringify(err)}`);
            return []; // Continuation logic has already been skipped, so return normally
        }
        return [];
    }
}

async function fetchRecord({ type, recordId, sourceType }) {
    if (_.isNil(type) || _.isNil(recordId)) {
        return null;
    }

    abortController.abort();
    abortController = new AbortController();

    let fields = '';

    switch (type) {
        case 'cheetahProject':
            fields = queryConstants.CHEETAHPROJECTFIELDS;
            break;

        case 'cheetahRun':
            fields = queryConstants.CHEETAHRUNFIELDS;
            break;

        default:
            fields = 'id';
            break;
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: `{ ${type}(id: "${recordId}", sourceType:"${sourceType}"  ) { ${fields} } }` }),
        // signal: abortController.signal,
    };

    try {
        const response = await fetch('/graphql', requestOptions);
        const data = await response.json();
        if (data.data) {
            // console.log(JSON.stringify(data.data[type]));
            return data.data[type];
        }

        const { errors } = data;
        if (!_.isNil(errors)) {
            console.error(JSON.stringify(errors));
            return ['errors were detected'];
        }

        return ['empty'];
    } catch (err) {
        if (err.name === 'AbortError') {
            console.error(`getRecord [${type}] ${JSON.stringify(err)}`);
            return []; // Continuation logic has already been skipped, so return normally
        }
        return [];
    }
}

async function fetchSystemInfoRecord({ recordId, sourceType }) {
    if (_.isNil(recordId)) {
        return null;
    }

    abortController.abort();
    abortController = new AbortController();

    const fields = queryConstants.CHEETAHSYSTEMINFORMATIONFIELDS;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            query: `{ ${queryConstants.QUERYCHEETAHSYSTEMINFORECORD}(recordId:"${recordId}", sourceType:"${sourceType}") { ${fields} } }`,
        }),
        // signal: abortController.signal,
    };

    try {
        const response = await fetch('/graphql', requestOptions);
        const data = await response.json();
        if (data.data) {
            return data.data[queryConstants.QUERYCHEETAHSYSTEMINFORECORD];
        }

        const { errors } = data;
        if (!_.isNil(errors)) {
            console.error(JSON.stringify(errors));
            return ['errors were detected'];
        }

        return ['empty'];
    } catch (err) {
        if (err.name === 'AbortError') {
            console.error(
                `getSystemInfoRecord [${queryConstants.QUERYCHEETAHSYSTEMINFORECORD}] ${JSON.stringify(err)}`
            );
            return []; // Continuation logic has already been skipped, so return normally
        }
        return [];
    }
}

async function fetchSystemInfoRecordByProjectSession({ projectId, sessionId }) {
    if (_.isNil(projectId) || _.isNil(sessionId)) {
        return null;
    }

    abortController.abort();
    abortController = new AbortController();

    const fields = queryConstants.CHEETAHSYSTEMINFORMATIONFIELDS;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            query: `{ ${queryConstants.QUERYCHEETAHSYSTEMINFORECORD}(projectId:${projectId},cheetahSessionIdText:"${sessionId}") { ${fields} } }`,
        }),
        // signal: abortController.signal,
    };

    try {
        const response = await fetch('/graphql', requestOptions);
        const data = await response.json();
        if (data.data) {
            // console.log(JSON.stringify(data.data[type]));
            return data.data[queryConstants.QUERYCHEETAHSYSTEMINFORECORD];
        }

        const { errors } = data;
        if (!_.isNil(errors)) {
            console.error(JSON.stringify(errors));
            return ['errors were detected'];
        }

        return ['empty'];
    } catch (err) {
        if (err.name === 'AbortError') {
            console.error(
                `getSystemInfoRecord [${queryConstants.QUERYCHEETAHSYSTEMINFORECORD}] ${JSON.stringify(err)}`
            );
            return []; // Continuation logic has already been skipped, so return normally
        }
        return [];
    }
}

async function fetchDataList({ listName }) {
    if (_.isNil(listName)) {
        return null;
    }

    abortController.abort();
    abortController = new AbortController();

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: `{ ${listName} }` }),
        signal: abortController.signal,
    };

    try {
        const response = await fetch('/graphql', requestOptions);
        const data = await response.json();
        if (data.data && data.data.versions) {
            return data.data.versions.slice(0);
        }

        if (data.data && data.data.cheetahProjects) {
            return data.data.cheetahProjects.slice(0);
        }

        const { errors } = data;
        if (!_.isNil(errors)) {
            console.error(JSON.stringify(errors));
            return ['errors were detected'];
        }

        return ['empty'];
    } catch (err) {
        if (err.name === 'AbortError') {
            return []; // Continuation logic has already been skipped, so return normally
        }
        return [];
    }
}

async function fetchPagedCheetahProjects({
    refresh = false,
    first,
    afterCursor,
    last,
    beforeCursor,
    order,
    orderBy,
    orderType,
    searchValue,
    searchField,
}) {
    abortController.abort();
    abortController = new AbortController();

    const pageArgs = [];
    pageArgs.push(`refresh:${refresh}`);

    if (!_.isNil(first) && !_.isNil(last)) {
        throw new Error(`Both 'first' and 'last' provided. Only one or the other should be given.`);
    }

    // 'next page' arguments.
    if (!_.isNil(first) && !_.isNil(afterCursor)) {
        pageArgs.push(`first:${first},afterCursor:"${afterCursor}"`);
    }

    // 'previous page' arguments.
    if (!_.isNil(last) && !_.isNil(beforeCursor)) {
        pageArgs.push(`last:${last},beforeCursor:"${beforeCursor}"`);
    }

    // order (sorting).
    if (!_.isNil(order)) {
        pageArgs.push(`order:"${order}"`);

        if (!_.isNil(orderBy)) {
            pageArgs.push(`orderBy:"${orderBy}"`);
        }

        if (!_.isNil(orderType)) {
            pageArgs.push(`orderType:"${orderType}"`);
        }
    }

    if (!_.isNil(searchValue)) {
        pageArgs.push(`searchValue:"${searchValue}"`);

        if (!_.isNil(searchField)) {
            pageArgs.push(`searchField:"${searchField}"`);
        }
    }

    const queryArgs = `(${pageArgs.join(',')})`;
    const queryName = queryConstants.QUERYCHEETAHPROJECTSPAGED;
    const graphQlQuery = `{ ${queryName}${queryArgs} {${queryConstants.QUERYCHEETAHPROJECTSCURSORFIELDS}} }`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: graphQlQuery }),
        signal: abortController.signal,
    };

    try {
        const response = await fetch('/graphql', requestOptions);
        const data = await response.json();
        const { data: innerData } = data;

        if (!_.isNil(innerData)) {
            return innerData; // totalCount, edges (list), pageInfo. - reg.
        }

        const { errors } = data;
        if (!_.isNil(errors)) {
            console.error(JSON.stringify(errors));
            return { hasErrors: true, errors: errors };
        }

        return { isEmpty: true };
    } catch (err) {
        if (err.name === 'AbortError') {
            return []; // Continuation logic has already been skipped, so return normally
        }

        return err.message;
    }
}

async function fetchDocuments({ version }) {
    if (_.isNil(version)) {
        return null;
    }

    abortController.abort();
    abortController = new AbortController();

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: `{ documentation(version: "${version}") {category document html} }` }),
        signal: abortController.signal,
    };

    try {
        const response = await fetch('/graphql', requestOptions);
        const data = await response.json();
        if (data.data && data.data.documentation) {
            return data.data.documentation.slice(0);
        }
        return ['empty'];
    } catch (err) {
        if (err.name === 'AbortError') {
            return []; // Continuation logic has already been skipped, so return normally
        }
        return [];
    }
}

async function fetchImage({ imageId }) {
    if (_.isNil(imageId)) {
        return null;
    }

    abortController.abort();
    abortController = new AbortController();

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: `{ demoImage(imageId: "${imageId}") }` }),
        signal: abortController.signal,
    };

    try {
        const response = await fetch('/graphql', requestOptions);
        const data = await response.json();

        const { errors } = data;
        if (!_.isNil(errors)) {
            console.error(JSON.stringify(errors));
            return ['getImage - errors were detected'];
        }

        if (data.data && data.data.demoImage) {
            return new Buffer.from(data.data.demoImage, 'base64');
        }

        return ['empty'];
    } catch (err) {
        if (err.name === 'AbortError') {
            return []; // Continuation logic has already been skipped, so return normally
        }
        return [];
    }
}

async function fetchBlob({ recordId }) {
    if (_.isNil(recordId)) {
        return null;
    }

    const res = await fetch(`/api/getRecord/${recordId}`);
    return await res.blob();
}
