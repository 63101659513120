/**
 * Created by 510004 on 1/16/2020.
 */

export const formatService = {
    formatBytes,
    formatDate,
};

function getLocale() {
    return navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
}

function formatBytes(a, b) {
    if (0 === a) {
        return '0 Bytes';
    }

    const c = 1024,
        d = b || 2,
        e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
}

function formatDate(dateString) {
    if (!dateString) {
        return dateString;
    }

    const iFormat = new Intl.DateTimeFormat(getLocale(), {
        hour12: true,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });

    return iFormat.format(new Date(dateString));
}
