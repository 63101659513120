/**
 * Created by 510004 on 1/24/2020.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import FormField from '../formField';
import { compose } from 'recompose';

const styles = () => ({
    formContainer: {
        display: 'grid',
        height: '100%',
        gridTemplateRows: '1fr auto',
    },
    fieldsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 300px)',
        gridAutoRows: '100px',
        padding: '10px',
        gridGap: '10px',
    },
    runsContainer: {
        display: 'grid',
        gridGap: '10px',
    },
    fieldContainer: {
        display: 'grid',
    },
    runsHeaderText: {
        fontSize: 'inherit',
        fontWeight: '600',
    },
});

const fields = [
    { id: 'loginUsername', label: 'User Name' },
    { id: 'logStartTime', label: 'Log Start', type: 'date' },
    { id: 'softwareStartTime', label: 'Software Start', type: 'date' },
    { id: 'softwareCheetahVersion', label: 'Cheetah Version' },
    { id: 'softwareElectronVersion', label: 'Electron Version' },
    { id: 'softwareDaqVersion', label: 'DAQ Version' },
    { id: 'osDistro', label: 'OS' },
    { id: 'osPlatform', label: 'OS Platform' },
    { id: 'osHostname', label: 'OS Hostname' },
    { id: 'osSerial', label: 'OS Serial #' },
    { id: 'systemManufacturer', label: 'Manufacturer' },
    { id: 'systemModel', label: 'Model' },
    { id: 'systemSerial', label: 'Serial #' },
    { id: 'timeTimezone', label: 'Timezone' },
    { id: 'timeTimezoneName', label: 'Timezone Name' },
    { id: 'timeLocale', label: 'Locale' },
    { id: 'cheetahSessionIdText', label: 'Session ID' },
];

function CheetahSystemInfoBase(props) {
    const { classes, cheetahSystemInfo } = props;

    return (
        <div className={classes.formContainer}>
            <div className={classes.fieldsContainer}>
                {fields.map((field) => {
                    return (
                        <FormField
                            key={field.id}
                            label={field.label}
                            value={cheetahSystemInfo[field.id]}
                            type={field.type}
                        />
                    );
                })}
            </div>
        </div>
    );
}

CheetahSystemInfoBase.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    cheetahSystemInfo: PropTypes.object,
    timestamp: PropTypes.number,
    onRefreshClick: PropTypes.func,
};

const CheetahSystemInfo = compose(withStyles(styles))(CheetahSystemInfoBase);

export default CheetahSystemInfo;
