/**
 * Created by 300126 on 1/31/2019.
 */

import { combineReducers } from 'redux';

import { authentication } from './authenticationReducer';
import { users } from './usersReducer';
import { alert } from './alertReducer';
import { fileOperation } from './fileOperationReducer';
import { dataOperation } from './dataReducer';
import { navOperation } from './navReducer';

const rootReducer = combineReducers({
    authentication,
    dataOperation,
    navOperation,
    users,
    alert,
    fileOperation,
});

export default rootReducer;
