/**
 * Created by 510004 on 2/20/2019.
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Button, withStyles } from '@material-ui/core';

import { withFirebase } from '../firebase';
import * as ROUTES from '../../_constants/routeConstants';
import { compose } from 'recompose';
import { useNavigate } from 'react-router-dom';

const styles = () => ({
    signOut: {
        backgroundColor: 'transparent',
        color: 'inherit',
        minWidth: 'fit-content',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.5)',
        },
    },
});

function SignOutBase(props) {
    const navigate = useNavigate();

    const onClick = () => {
        const cheetahOnlineIFrame = document.getElementById('cheetahOnlineIFrame');
        if (cheetahOnlineIFrame) {
            // const data = {
            //     messageType: 'end-remote-session',
            // };
            // cheetahOnlineIFrame.contentWindow.postMessage(data, '*');
            cheetahOnlineIFrame.src = '';

            // Remove the iframe element from the DOM
            cheetahOnlineIFrame.parentNode.removeChild(cheetahOnlineIFrame);
        }
        props.firebase.doSignOut().then(() => {
            navigate(`../${ROUTES.SIGN_IN}`);
        });
    };

    const { classes } = props;

    return (
        <Button type="button" className={classes.signOut} onClick={onClick}>
            Sign Out
        </Button>
    );
}

SignOutBase.propTypes = {
    classes: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired,
    // history: PropTypes.object.isRequired,
};

const SignOutButton = compose(withStyles(styles), withFirebase)(SignOutBase);

export default SignOutButton;
