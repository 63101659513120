/**
 * Created by 510004 on 2/24/2020.
 */

import { TablePaginationActionsWrapped } from './tablePaginationActionsWrapped';
import TablePagination from '@material-ui/core/TablePagination';
import React from 'react';
import PropTypes from 'prop-types';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createTheme({
    overrides: {
        MuiIconButton: {
            root: {
                padding: '6px 12px',
            },
        },
        MuiTableCell: {
            root: {
                fontSize: 'inherit',
            },
        },
        MuiTablePagination: {
            caption: {
                fontSize: 'inherit',
            },
            root: {
                fontSize: 'inherit',
            },
        },
    },
});

function CustomPaginationBase(props) {
    const { colSpan, count = 0, rowsPerPage, page, pageSizeOptions } = props;

    return (
        <ThemeProvider theme={theme}>
            <table>
                <tbody>
                    <tr>
                        <TablePagination
                            rowsPerPageOptions={pageSizeOptions}
                            colSpan={colSpan}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={props.onRequestChangePage}
                            onRowsPerPageChange={props.onRequestChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </tr>
                </tbody>
            </table>
        </ThemeProvider>
    );
}

CustomPaginationBase.propTypes = {
    colSpan: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageSizeOptions: PropTypes.array.isRequired,
    onRequestChangePage: PropTypes.func.isRequired,
    onRequestChangeRowsPerPage: PropTypes.func.isRequired,
};

export const CustomPagination = CustomPaginationBase;
