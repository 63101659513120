/**
 * Created by 510004 on 2/20/2019.
 */
export const LANDING = '/';
export const CHEETAH = 'cheetah/';
export const CHEETAH_RUN = 'cheetah-run/';
export const CHEETAH_SYSTEM_INFO = 'cheetah-system-info/';
export const CHEETAH_PROJECT = 'cheetah-project/';
export const CHEETAH_PROJECTS = 'cheetah-projects/';
export const CHEETAH_WEB = 'cheetah-web/';
export const CHEETAH_PLAYBACK = 'cheetah-playback/';
export const SIGN_UP = 'signup/';
export const SIGN_IN = 'signin/';
export const VERIFY = 'signup-verify/';
export const LOGS = 'logs/';
export const MAPS = 'maps';
export const PASSWORD_FORGET = 'pw-forget/';
export const PLAYBACK = 'playback/';
export const ADMIN = 'admin/';

export const ADMIN_USER_ROLES = 'User Roles';
export const ADMIN_CLIENT_CURVES = 'Client Curves';

export const ADMIN_USER_TAGS = 'External User Tags';
export const ADMIN_CLIENT_USERS = 'External Users';
