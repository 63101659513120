/**
 * Created by 510004 on 2/7/2020.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { AuthUserContext } from './../session';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';

const defaultPadding = '0 15px 0 15px';
const styles = () => ({
    buttonContainer: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        position: 'relative',
        padding: '0',
    },
    downloadStyle: {
        padding: defaultPadding,
        textAlign: 'left',
        width: '1%',
    },
});

function DownloadButtonBase(props) {
    const { classes, fetchingFile, loading, requestedName, rowData } = props;

    return (
        <AuthUserContext.Consumer>
            {(authUser) =>
                authUser ? (
                    <div className={classes.buttonContainer}>
                        <Button
                            color="primary"
                            onClick={() => props.handleDownload({ authUser: authUser, fileName: rowData.name })}>
                            <CloudDownloadIcon />
                        </Button>
                        {loading && requestedName === rowData.name && fetchingFile && <CircularProgress size={32} />}
                    </div>
                ) : (
                    <Button color="primary" disabled={true}>
                        <CloudDownloadIcon />
                    </Button>
                )
            }
        </AuthUserContext.Consumer>
    );
}

DownloadButtonBase.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    rowData: PropTypes.object.isRequired,
    requestedName: PropTypes.string,
    fetchingFile: PropTypes.bool,
    handleDownload: PropTypes.func,
};

const DownloadButton = compose(withStyles(styles))(DownloadButtonBase);

export default DownloadButton;
