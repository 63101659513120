/**
 * Created by 700290 on 6/20/2023.
 */
import { configService } from '../../_services';
import React, { useEffect, useState } from 'react';
const iframeCOEP = 'require-corp';
export default function CheetahWebBase() {
    const [url, setUrl] = useState('');
    const [isCheetahOnline, setIsCheetahOnline] = useState(false);
    const cheetahOnlineIFrame = React.useRef(null);
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    getUrl(setUrl, authUser);

    useEffect(() => {
        async function checkIfCheetahIsOnline() {
            const isOnline = await configService.checkIfCheetahIsOnline();
            setIsCheetahOnline(isOnline);
        }
        checkIfCheetahIsOnline();
    }, []);

    if (isCheetahOnline) {
        return onlineTemplate(url, cheetahOnlineIFrame);
    }
    return offlineTemplate();
}

function onlineTemplate(url, cheetahOnlineIFrame) {
    const handleIframeLoad = () => {
        const cheetahIFrame = window.frames['cheetahOnlineIFrame']; // Use the iframe's id
        if (cheetahIFrame) {
            const data = {
                messageType: 'save-auth-user-token',
                authUser: JSON.parse(localStorage.getItem('authUser')),
            };
            cheetahIFrame.contentWindow.postMessage(data, '*');
        } else {
            console.error('cheetahOnline IFrame window not found.');
        }
    };

    return (
        <section style={{ display: 'grid', alignSelf: 'stretch' }}>
            <iframe
                id="cheetahOnlineIFrame"
                ref={cheetahOnlineIFrame}
                src={url}
                width="100%"
                style={{ display: 'grid', alignSelf: 'stretch' }}
                allow="cross-origin-embedder-policy"
                sandbox={`allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-forms`}
                headers={{ 'Cross-Origin-Embedder-Policy': iframeCOEP }}
                onLoad={handleIframeLoad}></iframe>
        </section>
    );
}
function offlineTemplate() {
    return (
        <section style={{ display: 'grid', alignSelf: 'stretch' }}>
            <div style={{ textAlign: 'center', margin: '8px' }}>
                <h2>Cheetah Online viewer is unavailable at the moment.</h2>
                <h2>You can also try reloading this page.</h2>
            </div>
        </section>
    );
}

async function getUrl(setUrl, authUser) {
    const cheetahWebConfig = await configService.getCheetahWebConfig();
    if (!cheetahWebConfig) {
        throw new Error(`init - failed to load cheetahWeb Config`);
    }

    setUrl(cheetahWebConfig.url + '?token=' + authUser.stsTokenManager.accessToken);
}
