/**
 * Created by 700290 on 4/27/2024.
 */
'use strict';
import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Typography, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Checkbox,
    ListSubheader,
    CardHeader,
    CardContent,
} from '@mui/material';
import { GridLoader } from 'react-spinners';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    root2: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    card: {
        minWidth: '800px',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    roleList: {
        marginTop: theme.spacing(2),
    },
    loaderContainer: {
        alignSelf: 'center',
        display: 'grid',
        justifySelf: 'center',
    },
    loaderParentContainer: {
        display: 'grid',
        height: '100%',
    },
}));
const LOADER_SIZE_PX = 20;
const LOADER_COLOR = '#105F9A';
const ManageClientCurvesComponent = () => {
    const classes = useStyles();
    const [clientCurves, setClientCurves] = useState([]);
    const [curves, setCurves] = useState([]);
    const [selectedClientCurves, setSelectedClientCurves] = useState([]);
    const [selectedCurves, setSelectedCurves] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fetchCurves, setFetchCurves] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };
    const handleClientCurvesAdd = async () => {
        try {
            setLoading(true);
            const authUser = JSON.parse(localStorage.getItem('authUser'));
            const authHeader = {
                Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken,
                'Content-Type': 'application/json',
            };
            // const selectedUids = selectedClientCurves
            //     .filter((user) => selectedClientCurves.includes(user.email))
            //     .map((user) => user.uid);

            const response = await fetch('/api/addClientCurves', {
                method: 'POST',
                headers: authHeader,
                body: JSON.stringify({ clients: selectedClientCurves, curves: selectedCurves }),
            });
            const data = await response.text();
            if (!response.ok) {
                throw new Error(data || 'Something went wrong');
            }
            setFetchCurves((prevState) => !prevState);
            handleResponse(data, 'success', true);
        } catch (error) {
            handleResponse(error.message, 'error', false);
        }
    };

    const handleClientCurvesRemove = async () => {
        try {
            setLoading(true);
            const authUser = JSON.parse(localStorage.getItem('authUser'));
            const authHeader = {
                Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken,
                'Content-Type': 'application/json',
            };

            const response = await fetch('/api/removeClientCurves', {
                method: 'POST',
                headers: authHeader,
                body: JSON.stringify({ clients: selectedClientCurves, curves: selectedCurves }),
            });

            const data = await response.text();
            if (!response.ok) {
                throw new Error(data || 'Something went wrong');
            }
            setFetchCurves((prevState) => !prevState);
            handleResponse(data, 'success', true);
        } catch (error) {
            handleResponse(error.message, 'error', false);
        }
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleResponse = (status, severity, clearState) => {
        setMessage(status);
        setSeverity(severity);
        setLoading(false);
        setOpen(true);
        clearControlsState(clearState);
    };

    const clearControlsState = (shallClear) => {
        if (shallClear) {
            setSelectedCurves([]);
            setSelectedClientCurves([]);
        }
    };
    const handleCheckboxChange = (event, documentId) => {
        if (event.target.checked) {
            setSelectedClientCurves([...selectedClientCurves, documentId]);
        } else {
            setSelectedClientCurves(selectedClientCurves.filter((docId) => docId !== documentId));
        }
    };
    const handleCurveCheckboxChange = (event, curveName) => {
        if (event.target.checked) {
            setSelectedCurves([...selectedCurves, curveName]);
        } else {
            setSelectedCurves(selectedCurves.filter((curve) => curve !== curveName));
        }
    };

    const handleClientCurveChange = (event) => {
        setSelectedClientCurves(event.target.value);
    };

    useEffect(() => {
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        const requestOptions = {
            method: 'GET',
            headers: { Authorization: 'Bearer ' + authUser.stsTokenManager.accessToken },
        };

        fetch('/api/getCurves', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                //setUsers(data.users);
                //setRoles(data.roles);
                //setTags(data.tags);
                const curvesList = data.filter((item) => item.documentId === 'client curves');
                setCurves(curvesList);
                const clientCurveList = data.filter((item) => item.documentId !== 'client curves');
                console.log(clientCurveList);
                setClientCurves(clientCurveList);
                setLoading(false);
            })
            .catch((error) => handleResponse(error.message, 'error', false));
    }, [fetchCurves]);

    return (
        <div>
            {loading ? (
                <div className={classes.loaderParentContainer}>
                    <div className={classes.loaderContainer}>
                        <GridLoader size={LOADER_SIZE_PX} color={LOADER_COLOR} loading={loading} />
                    </div>
                </div>
            ) : (
                <div>
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        sx={{ top: '30%' }}>
                        <Alert onClose={handleClose} severity={severity}>
                            {message}
                        </Alert>
                    </Snackbar>
                    <Box className={classes.root2} noValidate autoComplete="off">
                        <Card className={classes.card}>
                            <CardHeader title="Manage Client Curves" />
                            <CardContent sx={{ width: '50%', margin: '0 auto' }}>
                                <Box className={classes.form}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl variant="standard" sx={{ width: '100%' }}>
                                                {/*<TextField id="uid" label="Uid" value={uid} onChange={(e) => setUid(e.target.value)} />*/}
                                                <InputLabel id="user-select-label-1">Clients</InputLabel>
                                                <Select
                                                    labelId="user-select-label-1"
                                                    id="user-select-1"
                                                    value={selectedClientCurves}
                                                    multiple
                                                    onChange={handleClientCurveChange}
                                                    renderValue={(selected) => selected[selected.length - 1]}>
                                                    {clientCurves.map((clientCurve, index) => (
                                                        <div key={index}>
                                                            <ListSubheader key={index}>
                                                                <Checkbox
                                                                    checked={
                                                                        selectedClientCurves.indexOf(
                                                                            clientCurve.documentId
                                                                        ) > -1
                                                                    }
                                                                    onChange={(event) =>
                                                                        handleCheckboxChange(
                                                                            event,
                                                                            clientCurve.documentId
                                                                        )
                                                                    }
                                                                />
                                                                {clientCurve.documentId}
                                                            </ListSubheader>
                                                            {clientCurve.values?.map((curveName, tagIndex) => (
                                                                <MenuItem key={tagIndex} value={curveName}>
                                                                    <Box pl={8}>
                                                                        <Typography variant="body2">
                                                                            {curveName}
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl variant="standard" sx={{ width: '100%' }}>
                                                <InputLabel id="tag-select-label">Curves</InputLabel>
                                                <Select
                                                    labelId="tag-select-label"
                                                    id="tag-select"
                                                    value={selectedCurves}
                                                    multiple
                                                    renderValue={(selected) => selected[selected.length - 1]}>
                                                    {curves.map((curve, index) => (
                                                        <div key={index}>
                                                            <ListSubheader key={index}>
                                                                {curve.documentId}
                                                            </ListSubheader>
                                                            {curve.values.map((curveName, tagIndex) => (
                                                                <MenuItem key={tagIndex} value={curveName}>
                                                                    <Box pl={8}>
                                                                        <Typography variant="body2">
                                                                            <Checkbox
                                                                                checked={
                                                                                    selectedCurves.indexOf(curveName) >
                                                                                    -1
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleCurveCheckboxChange(
                                                                                        event,
                                                                                        curveName
                                                                                    )
                                                                                }
                                                                            />
                                                                            {`${curveName}`}
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {/*<TextField id="role" label="Role" value={role} onChange={(e) => setRole(e.target.value)} />*/}
                                        {/*<Button variant="contained" color="primary" onClick={handleClientCurvesAdd}>*/}
                                        {/*    Add*/}
                                        {/*</Button>*/}
                                        {/*<Button variant="contained" color="secondary" onClick={handleClientCurvesRemove}>*/}
                                        {/*    Remove*/}
                                        {/*</Button>*/}
                                        <Grid item xs={12} container justifyContent="center" spacing={2}>
                                            <Grid item xs={6} sm={3}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleClientCurvesAdd}
                                                    fullWidth>
                                                    Add
                                                </Button>
                                            </Grid>

                                            <Grid item xs={6} sm={3}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleClientCurvesRemove}
                                                    fullWidth>
                                                    Remove
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="h6">Selected Clients</Typography>
                                <List>
                                    {selectedClientCurves.map((clientCurve, index) => (
                                        <ListItem key={index}>{clientCurve}</ListItem>
                                    ))}
                                </List>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="h6">Selected Curves</Typography>
                                <List>
                                    {selectedCurves.map((curve, index) => (
                                        <ListItem key={index}>{curve}</ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            )}{' '}
        </div>
    );
};

export default ManageClientCurvesComponent;
