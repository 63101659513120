/**
 * Created by 510004 on 2/21/2019.
 */

import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { withFirebase } from '../firebase';
import * as ROUTES from '../../_constants/routeConstants';
import { Paper, withStyles, Grid, TextField, Button, FormControl } from '@material-ui/core';
import { Face } from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import logo from '../../static/favicons/apple-touch-icon.png';
import { firestoreService } from '../../_services';

const styles = (theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: `${theme.spacing(3)}px`,
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`,
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        padding: '15px',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(1),
    },
});

const PasswordForgetPage = () => (
    <div>
        <PasswordForgetForm />
    </div>
);

const INITIAL_STATE = {
    email: '',
    resetSent: false,
    error: null,
};

class PasswordForgetFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = (event) => {
        const { email } = this.state;

        this.props.firebase
            .doFetchSignInMethods(email)
            .then(() => {
                this.props.firebase.doPasswordReset(email).then(() => {
                    this.setState({ ...INITIAL_STATE, resetSent: true });
                    firestoreService.updateIsNewUserClaim(this.props.firebase.getCurrentUser().uid).then(() => {
                        console.log('IsNewUser claim is set to false');
                    });
                });
            })
            .catch((error) => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, resetSent: false });
    };

    render() {
        const { classes } = this.props;
        const { email, resetSent, error } = this.state;
        const errorObj =
            error && error.message ? (error.message.startsWith('{') ? JSON.parse(error.message) : error) : null;

        const isInvalid = email === '';

        return (
            <form onSubmit={this.onSubmit}>
                <div className={classes.root}>
                    <img className={classes.img} alt="complex" src={logo} />
                    <h2 style={{ textAlign: 'center' }}>Please enter email to reset your password</h2>
                    <Paper className={classes.paper}>
                        <Grid container justifyContent="center" spacing={10} alignItems="flex-end">
                            <Grid item lg={2}>
                                <Face />
                            </Grid>
                            <Grid item lg={8}>
                                <FormControl fullWidth={true}>
                                    <TextField
                                        id="username"
                                        label="Username"
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        fullWidth
                                        autoFocus
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ textTransform: 'none' }}
                                disabled={isInvalid}
                                type="submit">
                                Reset My Password
                            </Button>
                        </Grid>
                    </Paper>
                </div>
                <Grid container justifyContent="center" style={{ marginTop: '10px', color: 'green' }}>
                    {resetSent === true ? <p>An email has been sent to this address.</p> : <p />}
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: '10px', color: 'red' }}>
                    {errorObj ? (
                        errorObj.error ? (
                            <p>{`${errorObj.error.status}`}</p>
                        ) : (
                            <p>{errorObj.message}</p>
                        )
                    ) : (
                        <p />
                    )}
                </Grid>
            </form>
        );
    }
}

PasswordForgetFormBase.propTypes = {
    classes: PropTypes.object.isRequired,
    email: PropTypes.string,
    firebase: PropTypes.object,
};

const PasswordForgetLink = () => (
    <p>
        <Link component={RouterLink} to={`../${ROUTES.PASSWORD_FORGET}`}>
            Forgot Password?
        </Link>
    </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = compose(withFirebase, withStyles(styles))(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
