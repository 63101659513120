/**
 * Created by 510004 on 9/3/2019.
 */
import { dataConstants } from '../_constants';
import { fetchService } from '../_services';
import { alertActions } from './';
import _ from 'lodash';

export const dataActions = {
    getImage,
    getList,
    getProjectsList,
    getRecord,
    getSystemInfoRecord,
    getSystemInfoRecordByProjectSession,
    getBlob,
    resetRecord,
};

function resetRecord({ recordType }) {
    return (dispatch) => {
        return dispatch(request(recordType));
    };

    function request(recordType) {
        return { type: dataConstants.RESET_RECORD, recordType: recordType };
    }
}

function getList({ listName }) {
    return async (dispatch) => {
        dispatch(request());

        try {
            const list = await fetchService.fetchList({ listName: listName });
            dispatch(success(list));
        } catch (error) {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
    };

    function request() {
        return { type: dataConstants.GET_LIST_REQUEST };
    }
    function success(list) {
        return { type: dataConstants.GET_LIST_SUCCESS, list };
    }
    function failure(error) {
        return { type: dataConstants.GET_LIST_FAILURE, error };
    }
}

function getProjectsList({
    refresh = false,
    first,
    afterCursor,
    last,
    beforeCursor,
    order,
    orderBy,
    orderType,
    searchValue,
    searchField,
}) {
    return async (dispatch) => {
        dispatch(request());

        afterCursor = !_.isNil(first) && _.isNil(afterCursor) ? 'null' : afterCursor;
        beforeCursor = !_.isNil(last) && _.isNil(beforeCursor) ? 'null' : beforeCursor;

        try {
            // const list = await fetchService.fetchDataList({ listName: listName });
            const result = await fetchService.fetchPagedCheetahProjects({
                refresh: refresh,
                first: first,
                afterCursor: afterCursor,
                last: last,
                beforeCursor: beforeCursor,
                order: order,
                orderBy: orderBy,
                orderType: orderType,
                searchValue: searchValue,
                searchField: searchField,
            });

            const { hasErrors, isEmpty, errors, cheetahProjectsPaged } = result;

            if (hasErrors) {
                dispatch(failure(errors.toString()));
                dispatch(alertActions.error(errors.toString()));
                return;
            }

            if (isEmpty) {
                dispatch(failure('getProjectsList: Is empty payload.'));
                return;
            }

            if (!_.isNil(cheetahProjectsPaged)) {
                dispatch(success(cheetahProjectsPaged));
                return;
            }

            dispatch(failure(`getProjectsList: Unrecognized payload: ${JSON.stringify(result)}.`));
        } catch (error) {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
    };

    function request() {
        return { type: dataConstants.GET_PROJECTLIST_REQUEST };
    }
    function success(result) {
        return { type: dataConstants.GET_PROJECTLIST_SUCCESS, result: result };
    }
    function failure(error) {
        return { type: dataConstants.GET_PROJECTLIST_FAILURE, error };
    }
}

function getRecord({ type, recordId, sourceType }) {
    return async (dispatch) => {
        dispatch(request());

        try {
            //const record = await fetchService.fetchRecord({ type: type, recordId: recordId });
            const record = await fetchService.fetchRecord({ type: type, recordId: recordId, sourceType: sourceType });
            dispatch(success(record));
        } catch (error) {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
    };

    function request() {
        return { type: dataConstants.GET_RECORD_REQUEST };
    }
    function success(record) {
        return { type: dataConstants.GET_RECORD_SUCCESS, recordType: type, record };
    }
    function failure(error) {
        return { type: dataConstants.GET_RECORD_FAILURE, error };
    }
}

function getSystemInfoRecordByProjectSession({ projectId, sessionId }) {
    return async (dispatch) => {
        dispatch(request());

        try {
            const record = await fetchService.fetchSystemInfoRecordByProjectSession({
                projectId: projectId,
                sessionId: sessionId,
            });
            dispatch(success(record));
        } catch (error) {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
    };

    function request() {
        return { type: dataConstants.GET_RECORD_REQUEST };
    }
    function success(record) {
        return { type: dataConstants.GET_RECORD_SUCCESS, record };
    }
    function failure(error) {
        return { type: dataConstants.GET_RECORD_FAILURE, error };
    }
}

function getSystemInfoRecord({ recordId, sourceType }) {
    return async (dispatch) => {
        dispatch(request());

        try {
            const record = await fetchService.fetchSystemInfoRecord({
                recordId: recordId,
                sourceType: sourceType,
            });
            dispatch(success(record));
        } catch (error) {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
    };

    function request() {
        return { type: dataConstants.GET_RECORD_REQUEST };
    }
    function success(record) {
        return { type: dataConstants.GET_RECORD_SUCCESS, recordType: 'cheetahSystemInfo', record };
    }
    function failure(error) {
        return { type: dataConstants.GET_RECORD_FAILURE, error };
    }
}

function getImage({ imageId }) {
    return async (dispatch) => {
        dispatch(request());

        try {
            const record = await fetchService.fetchImage({ imageId: imageId });
            dispatch(success(record));
        } catch (error) {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
    };

    function request() {
        return { type: dataConstants.GET_IMAGE_REQUEST };
    }
    function success(record) {
        return { type: dataConstants.GET_IMAGE_SUCCESS, record };
    }
    function failure(error) {
        return { type: dataConstants.GET_IMAGE_FAILURE, error };
    }
}

function getBlob({ recordId }) {
    return (dispatch) => {
        dispatch(request());

        fetchService.fetchBlob({ recordId: recordId }).then(
            (record) => {
                dispatch(success(record, recordId));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: dataConstants.GET_BLOB_REQUEST };
    }
    function success(record, recordId) {
        return { type: dataConstants.GET_BLOB_SUCCESS, record, recordId };
    }
    function failure(error) {
        return { type: dataConstants.GET_BLOB_FAILURE, error };
    }
}
