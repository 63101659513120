/**
 * Created by 300126 on 2/1/2019.
 */

export function authHeader() {
    // return authorization header with jwt token
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token };
    }

    return {};
}
