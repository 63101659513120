import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import ArticleIcon from '@mui/icons-material/Article';
import InsightsIcon from '@mui/icons-material/Insights';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PetsIcon from '@material-ui/icons/Pets';
import StorageIcon from '@material-ui/icons/Storage';
import WebIcon from '@mui/icons-material/Web';

import PropTypes from 'prop-types';
import React from 'react';

/**
 * Created by 510004 on 1/24/2020.
 */

const MaterialIcon = ({ iconName }) => {
    if (iconName === 'AccountTree') {
        return <AccountTreeIcon />;
    }

    if (iconName === 'AdminPanel') {
        return <AdminPanelSettingsIcon />;
    }

    if (iconName === 'Dashboard') {
        return <DashboardIcon />;
    }

    if (iconName === 'ImportantDevices') {
        return <ImportantDevicesIcon />;
    }

    if (iconName === 'Article') {
        return <ArticleIcon />;
    }

    if (iconName === 'Insights') {
        return <InsightsIcon />;
    }

    if (iconName === 'Pets') {
        return <PetsIcon />;
    }

    if (iconName === 'QueryStats') {
        return <QueryStatsIcon />;
    }

    if (iconName === 'Storage') {
        return <StorageIcon />;
    }

    if (iconName === 'Web') {
        return <WebIcon />;
    }

    return null;
};

MaterialIcon.propTypes = {
    iconName: PropTypes.string.isRequired,
};

export default MaterialIcon;
