/**
 * Created by 510004 on 8/21/2019.
 */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createTheme, withStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { fileOperationActions } from '../../_actions';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withAuthorization } from '../session';

const styles = (theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: '15px',
    },
    docContainer: {
        display: 'grid',
        gridTemplateColumns: '1% 1fr 10%',
        gridTemplateRows: 'auto',
        gridTemplateAreas: `
            ". content ." 
        `,
    },
    docContent: {
        gridArea: 'content',
    },
    emptyTab: {
        height: 'calc(100vh - 120px)',
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}>
            <Box fontSize="inherit" p={0}>
                {children}
            </Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function CheetahBase(props) {
    const params = useParams();
    const currentVersion = params.version;
    const [value, setValue] = useState(0);
    const [version, setVersion] = useState(null);

    const theme = createTheme({
        overrides: {
            MuiAppBar: {
                colorDefault: {
                    backgroundColor: 'transparent',
                },
            },
            MuiPaper: {
                elevation4: {
                    boxShadow: 'none',
                },
            },
        },
    });

    useEffect(() => {
        if (currentVersion !== version) {
            setVersion(currentVersion);
            getDocuments({ version: currentVersion });
        }
    }, [currentVersion, version]);

    const handleChange = (e, newVal) => {
        setValue(newVal);
    };

    const getDocuments = ({ version }) => {
        const { dispatch } = props;
        dispatch(fileOperationActions.getDocuments({ version: version }));
    };

    const { classes, documents } = props;

    if (documents.length > 0) {
        const reg = /(href="#)/g;

        for (let i = 0, len = documents.length; i < len; i++) {
            const item = documents[i];

            const { html } = item;

            // Fix relative anchors with full subdirectory path. Our host page will have a <base> tag pointing to "/". - reg
            // ex: href="#anchor" -> href="cheetah/version#anchor".
            const relativePath = `cheetah/${version}`;
            const replacement = `href="${relativePath}#`;
            item.html = html.replace(reg, replacement);
        }
    }

    return (
        <Paper className={classes.paper}>
            <ThemeProvider theme={theme}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example">
                        {documents.map((doc, index) => (
                            <Tab key={`cat-${doc.category}`} label={doc.category} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                </AppBar>
            </ThemeProvider>

            {documents.map((doc, index) => (
                <TabPanel key={`panel-${doc.category}`} value={value} index={index}>
                    <Paper className={classes.root}>
                        <div className={classes.docContainer}>
                            <div
                                className={classes.docContent}
                                dangerouslySetInnerHTML={{
                                    __html: doc.html,
                                }}
                            />
                        </div>
                    </Paper>
                </TabPanel>
            ))}
        </Paper>
    );
}

CheetahBase.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    match: PropTypes.shape({
        params: PropTypes.shape({
            version: PropTypes.string,
        }),
    }),
    documents: PropTypes.array,
    resourceFile: PropTypes.object,
};

const mapStateToProps = (state) => {
    const { documents } = state.fileOperation;
    return {
        documents,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
    };
};

const condition = (authUser) => !!authUser;

const CheetahPage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withAuthorization(condition)
)(CheetahBase);

export default CheetahPage;
