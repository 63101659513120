/**
 * Created by 510004 on 2/20/2020.
 */
import { navConstants } from '../_constants';

const initialState = {
    breadcrumbs: [],
    to: null,
    from: null,
};

export function navOperation(state = initialState, action) {
    switch (action.type) {
        case navConstants.BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: action.breadcrumbs,
            };

        case navConstants.FROM:
            return {
                ...state,
                from: action.link,
            };

        case navConstants.TO:
            return {
                ...state,
                to: action.link,
            };

        default:
            return state;
    }
}
