/**
 * Created by 510004 on 9/3/2019.
 */
import { dataConstants } from '../_constants';

const initialState = {
    imageBuffer: [],
    list: [],
    blob: {},
    record: {},
    result: {},
    fetchingList: false,
    fetchingRecord: false,
};

export function dataOperation(state = initialState, action) {
    const { list, record, type, recordType, result } = action;

    switch (type) {
        case dataConstants.RESET_RECORD:
            return {
                ...state,
                [recordType]: null,
            };

        case dataConstants.GET_LIST_REQUEST:
            return {
                ...state,
                fetchingList: true,
            };
        case dataConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                list: list,
                fetchingList: false,
            };
        case dataConstants.GET_LIST_FAILURE:
            return {
                ...state,
                fetchingList: false,
            };

        case dataConstants.GET_PROJECTLIST_REQUEST:
            return {
                ...state,
                fetchingList: true,
            };
        case dataConstants.GET_PROJECTLIST_SUCCESS:
            return {
                ...state,
                result: result,
                fetchingList: false,
            };
        case dataConstants.GET_PROJECTLIST_FAILURE:
            return {
                ...state,
                fetchingList: false,
            };

        case dataConstants.GET_IMAGE_REQUEST:
            return {
                ...state,
                imageBuffer: [],
                fetchingRecord: true,
            };
        case dataConstants.GET_IMAGE_SUCCESS:
            return {
                ...state,
                imageBuffer: record,
                fetchingRecord: false,
            };
        case dataConstants.GET_IMAGE_FAILURE:
            return {
                ...state,
                fetchingRecord: false,
            };

        case dataConstants.GET_RECORD_REQUEST:
            return {
                ...state,
                fetchingRecord: true,
            };
        case dataConstants.GET_RECORD_SUCCESS:
            return {
                ...state,
                [recordType]: record,
                fetchingRecord: false,
            };
        case dataConstants.GET_RECORD_FAILURE:
            return {
                ...state,
                fetchingRecord: false,
            };

        case dataConstants.GET_BLOB_REQUEST:
            return {
                ...state,
                fetchingRecord: true,
            };
        case dataConstants.GET_BLOB_SUCCESS:
            return {
                ...state,
                blob: record,
                fetchingRecord: false,
            };
        case dataConstants.GET_BLOB_FAILURE:
            return {
                ...state,
                fetchingRecord: false,
            };

        default:
            return state;
    }
}
