/**
 * Created by Eric on 8/20/2019
 */
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { configService } from '../../_services';
import { styled, withStyles } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@material-ui/core/';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import MuiDrawer from '@mui/material/Drawer';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MaterialIcon from '../materialIcon';
import PropTypes from 'prop-types';
import * as ROUTES from '../../_constants/routeConstants';
import { ThemeProvider } from '@material-ui/styles';
import { dataActions } from '../../_actions';
import { navConstants, queryConstants } from '../../_constants';
import _ from 'lodash';
import { AuthUserContext } from '../session';

const drawerWidth = 170;
const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    link: {
        color: 'inherit',
        textDecoration: 'none',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

const getMenu = (claims) => {
    return [
        ...(!claims.isClientView
            ? [
                  {
                      id: 'projects',
                      label: 'Projects',
                      index: 0,
                      icon: 'AccountTree',
                      route: ROUTES.CHEETAH_PROJECTS,
                  },
              ]
            : []),
        ...(!claims.isClientView
            ? [
                  {
                      id: 'logs',
                      label: 'Logs',
                      index: 1,
                      icon: 'Storage',
                      route: ROUTES.LOGS,
                  },
              ]
            : []),
        ...(!claims.isClientView
            ? [
                  {
                      id: 'cheetah',
                      label: 'Cheetah Docs',
                      index: 2,
                      icon: 'Article',
                      route: `${ROUTES.CHEETAH}{latestVersion}`,
                      openKey: 'versionsOpen',
                      subItem: {
                          id: 'versions',
                          label: 'Logs',
                          index: 4,
                          route: ROUTES.CHEETAH,
                      },
                  },
              ]
            : []),
        ...(claims.canViewCheetahWeb
            ? [
                  {
                      id: 'cheetah-web',
                      label: 'Cheetah Online',
                      index: 3,
                      icon: 'Web',
                      route: ROUTES.CHEETAH_WEB,
                  },
              ]
            : []),
        ...(!claims.isClientView
            ? [
                  {
                      id: 'cheetah-playback',
                      label: 'Cheetah Playback',
                      index: 4,
                      icon: 'Web',
                      route: ROUTES.CHEETAH_PLAYBACK,
                  },
              ]
            : []),
        ...(claims.isWellDataAdmin
            ? [
                  {
                      id: 'admin',
                      label: 'Admin',
                      index: 5,
                      icon: 'AdminPanel',
                      openKey: 'adminOpen',
                      route: `${ROUTES.ADMIN}${ROUTES.ADMIN_USER_ROLES.replace(/ /g, '').toLowerCase()}`,
                      subItem: {
                          id: 'adminPages',
                          label: 'Admin',
                          index: 6,
                          route: ROUTES.ADMIN,
                      },
                  },
              ]
            : []),
    ];
};

const theme = createTheme({
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: '2rem',
            },
        },
        MuiListItemText: {
            root: {
                minWidth: 'min-content',
            },
            primary: {
                fontSize: 'inherit',
            },
        },
    },
});

function SidebarBase(props) {
    const [selectedId, setSelectedId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [targetPath, setTargetPath] = useState(null);
    const [clickedId, setClickedId] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const { dispatch, classes, versions, claims } = props;
    const menu = getMenu(claims);
    let { sidebarMenuState } = props;
    let open = sidebarMenuState === navConstants.DEFAULT || sidebarMenuState === navConstants.SIDEBAROPEN;
    const previousVersions = [...versions];
    const [isCheetahOnline, setIsCheetahOnline] = useState(false);
    const menuOpenItems = menu.flat().reduce((acc, item) => {
        return { ...acc, [item.openKey]: false };
    }, {});
    const [menuOpen, setMenuOpen] = useState(menuOpenItems);

    useEffect(() => {
        // 'versions' should be populated at least once in our rendering cycle. - reg.
        if (_.isNil(versions) || versions.length === 0) {
            getVersions();
            return;
        }

        // todo: find better way to prevent infinite looping here. - reg.
        if (JSON.stringify(previousVersions) === JSON.stringify(versions)) {
            return;
        }

        getVersions();
    }, [versions]);

    const handleDialogOpen = (event, currentPath, id) => {
        if (currentPath === 'cheetah-web/') {
            checkIfCheetahIsOnline(setIsCheetahOnline);
        }

        event.preventDefault();
        setTargetPath(currentPath);
        setClickedId(id);

        if (location.pathname === '/cheetah-web/' && currentPath !== 'cheetah-web/' && isCheetahOnline) {
            setOpenDialog(true);
            return;
        }

        findAndSetSelected(currentPath, id);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setTargetPath(null);
        setClickedId(null);
    };

    const getVersions = () => {
        dispatch(dataActions.getList({ listName: queryConstants.GETVERSIONS }));
    };

    const navigateAway = () => {
        setOpenDialog(false);
        const cheetahOnlineIFrame = document.getElementById('cheetahOnlineIFrame');
        window.addEventListener('message', handleMessageFromCheetahWeb, false);
        const data = {
            messageType: 'end-remote-session',
        };
        cheetahOnlineIFrame.contentWindow.postMessage(data, '*');
    };

    async function checkIfCheetahIsOnline(setIsCheetahOnline) {
        const isOnline = await configService.checkIfCheetahIsOnline();
        setIsCheetahOnline(isOnline);
    }
    function handleMessageFromCheetahWeb(ev) {
        if (typeof ev.data !== 'object') return;
        if (!ev.data.type) return;
        if (ev.data.type !== 'remote-session-ended') return;

        const cheetahOnlineIFrame = document.getElementById('cheetahOnlineIFrame');
        if (cheetahOnlineIFrame) {
            // Clear the src attribute to stop any processes inside the iframe
            cheetahOnlineIFrame.src = '';

            // Remove the iframe element from the DOM
            cheetahOnlineIFrame.parentNode.removeChild(cheetahOnlineIFrame);
        }

        findAndSetSelected(targetPath, clickedId);
    }

    const findAndSetSelected = (path, id) => {
        const menuRoot = menu.find((item) => item.id === id);

        if (!_.isNil(menuRoot) && !_.isNil(menuRoot.subItem) && !_.isNil(menuRoot.openKey)) {
            setMenuOpen({ ...menuOpen, [menuRoot.openKey]: !menuOpen[menuRoot.openKey] });
        }

        setSelectedId(id);

        if (path) {
            navigate(path);
        }
    };

    const handleListSubItemClick = (event, id, subItemId, childId) => {
        event.stopPropagation();
        const menuRoot = menu.find((item) => {
            if (!_.isNil(subItemId)) {
                return !_.isNil(item.subItem) && item.subItem.id === subItemId;
            }

            return item.id === id;
        });

        // Versions has subitems. As long as selection is one of these, keep this item open. - reg.
        if (
            !_.isNil(menuRoot) &&
            !_.isNil(menuRoot.subItem) &&
            (menuRoot.subItem.id === 'versions' || menuRoot.subItem.id === 'adminPages')
        ) {
            //setVersionsOpen(true);
            setMenuOpen({ ...menuOpen, [menuRoot.openKey]: true });
            setSelectedId(`${menuRoot.subItem.id}_${childId}`);
            const path = `${menuRoot.subItem.route}${childId}`;
            setTargetPath(path);

            if (path) {
                navigate(path);
            }

            return;
        }
        setMenuOpen({ ...menuOpen, [menuRoot.openKey]: false });
        //setVersionsOpen(false);
        setSelectedId(id);
    };

    const latestVersion = versions[0]; // assume sorted list, most current is first item. - reg.
    const adminPages = [
        ROUTES.ADMIN_CLIENT_CURVES,
        ROUTES.ADMIN_CLIENT_USERS,
        ROUTES.ADMIN_USER_ROLES,
        ROUTES.ADMIN_USER_TAGS,
    ];
    const subItems = { versions: versions, adminPages: adminPages };

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        minHeight: '4rem',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }));

    const handleDrawerClose = () => {
        console.log('handleDrawerClose');
        props.handleMenuClick();
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <Drawer variant="persistent" anchor="left" open={open}>
                        <DrawerHeader className={classes.drawerHeader}>
                            <IconButton onClick={() => handleDrawerClose()}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <AuthUserContext.Consumer>
                            {(authUser) =>
                                !_.isNil(authUser) && authUser.emailVerified ? (
                                    <div>
                                        <List component="nav" aria-label="main mailbox folders">
                                            {menu.map((item) => {
                                                const parsedRoute =
                                                    item.route.indexOf('{latestVersion}') > 0
                                                        ? item.route.replace('{latestVersion}', latestVersion)
                                                        : item.route;
                                                return (
                                                    <Link
                                                        key={item.id}
                                                        className={classes.link}
                                                        onClick={(event) =>
                                                            handleDialogOpen(event, parsedRoute, item.id)
                                                        }>
                                                        <ListItem
                                                            sx={{ display: 'block' }}
                                                            selected={selectedId === item.id}>
                                                            <ListItemIcon
                                                                sx={{
                                                                    minWidth: 0,
                                                                    mr: open ? 3 : 'auto',
                                                                    justifyContent: 'center',
                                                                }}>
                                                                {MaterialIcon({ iconName: item.icon })}
                                                            </ListItemIcon>
                                                            <ListItemText primary={item.label} />
                                                            {item.subItem ? (
                                                                menuOpen[item.openKey] ? (
                                                                    <ExpandLess />
                                                                ) : (
                                                                    <ExpandMore />
                                                                )
                                                            ) : (
                                                                ''
                                                            )}
                                                        </ListItem>
                                                        {item.subItem ? (
                                                            <Collapse
                                                                in={menuOpen[item.openKey]}
                                                                timeout="auto"
                                                                unmountOnExit>
                                                                <List component="div" disablePadding>
                                                                    {subItems[item.subItem.id] ? (
                                                                        subItems[item.subItem.id].map((childItem) => (
                                                                            <Link
                                                                                key={`version-${childItem}`}
                                                                                to={{
                                                                                    pathname: `${
                                                                                        item.subItem.route
                                                                                    }${childItem
                                                                                        .replace(/ /g, '')
                                                                                        .toLowerCase()}`,
                                                                                }}
                                                                                onClick={(event) =>
                                                                                    handleListSubItemClick(
                                                                                        event,
                                                                                        item.id,
                                                                                        item.subItem.id,
                                                                                        childItem
                                                                                    )
                                                                                }
                                                                                className={classes.link}>
                                                                                <ListItem
                                                                                    button
                                                                                    selected={
                                                                                        selectedId ===
                                                                                        `${item.subItem.id}_${childItem}`
                                                                                    }
                                                                                    className={classes.nested}>
                                                                                    <ListItemText primary={childItem} />
                                                                                </ListItem>
                                                                            </Link>
                                                                        ))
                                                                    ) : (
                                                                        <Link
                                                                            to={{ pathname: `${item.subItem.route}` }}
                                                                            className={classes.link}
                                                                            onClick={(event) =>
                                                                                handleDialogOpen(
                                                                                    event,
                                                                                    `${item.subItem.route}`,
                                                                                    item.subItem.index
                                                                                )
                                                                            }>
                                                                            <ListItem
                                                                                button
                                                                                selected={
                                                                                    selectedId === item.subItem.id
                                                                                }
                                                                                className={classes.nested}>
                                                                                <ListItemText
                                                                                    primary={item.subItem.label}
                                                                                />
                                                                            </ListItem>
                                                                        </Link>
                                                                    )}
                                                                </List>
                                                            </Collapse>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Link>
                                                );
                                            })}
                                        </List>
                                    </div>
                                ) : (
                                    <div />
                                )
                            }
                        </AuthUserContext.Consumer>
                    </Drawer>
                </div>
            </ThemeProvider>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <p>If you navigate away you will lose your session. Are you sure you want to navigate away?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={navigateAway}>Yes</Button>
                    <Button onClick={handleDialogClose} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

SidebarBase.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    versions: PropTypes.array.isRequired,
    sidebarMenuState: PropTypes.string.isRequired,
    handleMenuClick: PropTypes.func,
    claims: PropTypes.object,
};

const mapStateToProps = (state) => {
    const { list: versions } = state.dataOperation;
    return {
        versions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
    };
};

const Sidebar = compose(withFirebase, withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(SidebarBase);

export default Sidebar;
