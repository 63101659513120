/**
 * Created by 510004 on 2/20/2020.
 */

import React from 'react';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
// import { compose } from 'recompose';
import _ from 'lodash';

function getNavOperation(state) {
    const { navOperation } = state;
    if (_.isNil(navOperation)) {
        return {};
    }
    return navOperation;
}

function BreadcrumbsBase() {
    const handleClick = () => {};
    const navOperation = useSelector((state) => getNavOperation(state));
    const { breadcrumbs, to } = navOperation;
    const breadcrumbsFinal = breadcrumbs.slice() || [];

    if (!_.isNil(to)) {
        breadcrumbsFinal.push(to);
    }

    const bypass = breadcrumbsFinal.includes(null) || breadcrumbsFinal.length === 0;

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {bypass ? (
                <div>Test</div>
            ) : (
                breadcrumbsFinal.map((link, index) => {
                    return index < breadcrumbsFinal.length - 1 && !_.isNil(link) && !_.isNil(link.url) ? (
                        <Link key={index} color="inherit" to={link.url} onClick={handleClick}>
                            {link.title}
                        </Link>
                    ) : !_.isNil(link) ? (
                        <div key={index} color="inherit" aria-current="page">
                            {link.title}
                        </div>
                    ) : (
                        <div key={index} color="inherit" aria-current="page" />
                    );
                })
            )}
        </Breadcrumbs>
    );
}

BreadcrumbsBase.propTypes = {};

const CustomBreadcrumbs = BreadcrumbsBase;

export default CustomBreadcrumbs;
