/**
 * Created by 510004 on 2/4/2020.
 */

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';

const styles = (theme) => ({
    head: {
        backgroundColor: '#5790b8',
        color: theme.palette.common.white,
    },
});

const formatValue = ({ value, format, type }) => {
    type = type || 'string';

    switch (type) {
        case 'bool':
            value = value === true ? 'Yes' : '';
            break;

        case 'date':
        case 'number':
            if (format) {
                value = format(value);
            }
            break;

        default:
            break;
    }
    return value;
};

function CustomTableCellBase(props) {
    const { value } = props;
    let { column } = props;

    column = column || {};

    return (
        <TableCell align={column.align} style={{ width: column.minWidth }}>
            {formatValue({ value: value, format: column.format, type: column.type })}
        </TableCell>
    );
}

CustomTableCellBase.propTypes = {
    column: PropTypes.object,
    value: PropTypes.any,
};

export const CustomTableCell = withStyles(styles)(CustomTableCellBase);
