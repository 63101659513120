/**
 * Created by 700290 on 3/9/2024.
 */
'use strict';
import { configService } from '../../_services';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
const iframeCOEP = 'require-corp';
export default function CheetahPlaybackBase() {
    const [url, setUrl] = useState('');
    const [isCheetahOnline, setIsCheetahOnline] = useState(false);
    const cheetahIFrame = React.useRef(null);
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get('projectId');
    const runId = queryParams.get('runId');

    getUrl(setUrl, projectId, runId, authUser);

    useEffect(() => {
        async function checkIfCheetahIsOnline() {
            const isOnline = await configService.checkIfCheetahIsOnline();
            setIsCheetahOnline(isOnline);
        }

        checkIfCheetahIsOnline();
    }, []);

    if (isCheetahOnline) {
        return onlineTemplate(url, cheetahIFrame);
    }
    return offlineTemplate();
}

function onlineTemplate(url, cheetahIFrame) {
    return (
        <section style={{ display: 'grid', alignSelf: 'stretch' }}>
            <iframe
                id="cheetahIFrame"
                ref={cheetahIFrame}
                src={url}
                width="100%"
                style={{ display: 'grid', alignSelf: 'stretch' }}
                referrerPolicy={'origin-when-cross-origin'}
                sandbox={`allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-forms allow-downloads`}
                headers={{ 'Cross-Origin-Embedder-Policy': iframeCOEP }}></iframe>
        </section>
    );
}
function offlineTemplate() {
    return (
        <section style={{ display: 'grid', alignSelf: 'stretch' }}>
            <div style={{ textAlign: 'center', margin: '8px' }}>
                <h2>Cheetah Playback is unavailable at the moment.</h2>
                <h2>You can also try reloading this page.</h2>
            </div>
        </section>
    );
}

async function getUrl(setUrl, projectId, runId, authUser) {
    const cheetahWebConfig = await configService.getCheetahWebConfig();
    if (!cheetahWebConfig) {
        throw new Error(`init - failed to load cheetahWeb Config`);
    }

    setUrl(
        cheetahWebConfig.playback_url +
            '/projectMain/start?projectId=' +
            projectId +
            '&runId=' +
            runId +
            '&token=' +
            authUser.stsTokenManager.accessToken
    );
}
